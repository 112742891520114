import React from "react";
import { Button } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";

const CheckboxInput = ({
  name,
  register,
  value,
  image,
  options,
  next,
  control,
  Controller,
  watch
}) => {
  return (
    // <>
    //   <Controller
    //     // key={option.value}
    //     render={({ field: { onChange, value, name } }) =><>
    //       {options?.map((option) => (
    //         <div className="hidden-radio" key={option.value}>
    //           <input
    //             type="checkbox"
    //             defaultChecked={watch(name) == option.value}
    //             name={name}
    //             id={name + option.value}
    //             className={"d-none"}
    //             value={option.value}
    //             // onClick={(e) => {
               
    //             //   onChange(e);
    //             // }} // send value to hook form
    //           />
    //           <Button
    //             as="label"
    //             variant="outline-primary"
    //             className="radio-btn w-100 py-2 fs-bold mb-4"
    //             htmlFor={name + option.value}
    //           >
    //             {option?.image ? (
    //               <img
    //                 src={option?.image}
    //                 alt="name"
    //                 className="rounded-5 py-2"
    //               />
    //             ) : null}
    //             {option?.label ? option?.label : null}
    //           </Button>
    //         </div>
    //       ))}
    //            <Button variant="outline-primary" onClick={next} className=" mt-4 d-block mx-auto px-5 btn-lg  rounded-5 ">next 
    //   <BsArrowRight className="ms-2"/>
    //   </Button>
    //       </>
    //     }
    //     name={name}
    //     control={control}
    //   />
    // </>
    <>
    {options?.map((option) => (
      <div className="hidden-radio" key={option.value}>
        <input
        {...register(name, { required: true })}
          type="checkbox"
          name={name}
          id={name + option.value}
          className={"d-none"}
          value={option.value}

        />
        <Button
          as="label"
          variant="outline-primary"
          className="radio-btn w-100 py-2 fs-bold mb-4"
          htmlFor={name + option.value}
        >
          {option?.image ? (
            <img
              src={option?.image}
              alt="name"
              className="rounded-5 py-2"
            />
          ) : null}
          {option?.label ? option?.label : null}
        </Button>
      </div>
    ))}
    <Button
      variant="outline-primary"
      onClick={next}
      className=" mt-4 d-block mx-auto px-5 btn-lg  rounded-5 "
    >
      next <BsArrowRight className="ms-2" />
    </Button>
  </>
  );
};

export default CheckboxInput;
