import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "../../pages/Auth/UserContext/UserData.context";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";

import { setCookie } from "../../pages/Auth/UserContext/ForCookie";
import Error from "./Error";
import useAxios, { url } from "../../pages/Auth/Client";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import i18next from "i18next";

const UserInfo = ({ data, update, mode ,noheading}) => {
  const { t } = useTranslation(); // Initialize the translation function
  const { userData, setUserData } = useContext(UserDataContext);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const axios_common = useAxios();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (mode !== "register") {
      reset(userData.user);
    }
  }, [userData]);
  
  const onSubmit = (data) => {
    setError(false);
    setSuccess(false);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const obj = { register: axios, update: axios_common };
    obj[mode]
      .post(
        `${url}${mode === "register" ? "auth/register" : "updateProfile"}`,
        { ...data },
        config
      )
      .then((res) => {
        const userDataUpdate = res?.data;
        if (mode === "register") {
          setCookie(
            "access_token",
            userDataUpdate.data.access_token,
            userDataUpdate.data.expires_in * 1000
          );
          localStorage.setItem("user", JSON.stringify(userDataUpdate.data.user));
          setUserData({
            ...userData,
            ...userDataUpdate.data,
          });
          navigate("/");
        } else {
          localStorage.setItem("user", JSON.stringify(userDataUpdate));
          setUserData({
            ...userData,
            user: userDataUpdate,
          });
          setSuccess(t('update Successfully!')); // Translate success message
        }
      })
      .catch((error) => {
        mode === "register"
          ? setError(error.response.data.error)
          : setError(error.error);
      });
  };

  return (
    <Form className="col-8 mx-auto mb-5" onSubmit={handleSubmit(onSubmit)}>
      {success ? (
        <Alert
          className="text-capitalize col-md-6 mx-auto"
          variant="success"
          onClose={() => setSuccess(false)}
          dismissible
        >
          {success}
        </Alert>
      ) : null}
      <Row className="flex-column justify-content-center align-items-center">
        <Col md={noheading || 6} >
          {mode === "view" && <label className="text-uppercase">{t("your name")}</label>}
          <Form.Control
            disabled={mode === "view"}
            {...register("name", { required: mode === "register" })}
            size="lg"
            type="text"
            placeholder={t("your name")} 
            className={(mode === "view" ? "fw-bold" : "bg-light") + " fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"}
          />
          <Error error={error} name="name" />
        </Col>
        <Col md={noheading || 6} >
          {mode === "view" && <label className="text-uppercase">{t("your phone")}</label>}
          <Form.Control
            {...register("phone", { required: mode === "register" })}
            disabled={mode === "view"}
            size="lg"
            type="tel"
            placeholder={t("your phone")} 
            className={(mode === "view" ? " fw-bold" : "bg-light") + " fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"}
          />
          <Error error={error} name="phone" />
        </Col>
        <Col md={noheading || 6} >
          {mode === "view" && <label className="text-uppercase">{t("Your e-mail")}</label>}
          <Form.Control
            {...register("email", { required: mode === "register" })}
            disabled={mode === "view"}
            size="lg"
            type="email"
            placeholder={t("Your e-mail")} 
            className={(mode === "view" ? " fw-bold" : "bg-light") + " fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"}
          />
          <Error error={error} name="email" />
        </Col>
        {mode !== "view" && (
          <>
            <Col md={noheading || 6} >
              <Form.Control
                {...register("password", { required: mode === "register" })}
                disabled={mode === "view"}
                size="lg"
                type="password"
                placeholder={t("password")} 
                className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
              />
              <Error error={error} name="password" />
            </Col>
            {mode === "register" && (
              <Col md={noheading || 6} >
                <Form.Control
                  {...register("password_confirmation", {
                    required: mode === "register",
                  })}
                  size="lg"
                  type="password"
                  placeholder={t("confirm password")} 
                  className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
                />
                <Error error={error} name="password_confirmation" />
              </Col>
            )}

            <div className="text-center">
              <Button
                className={"text-uppercase btn mt-4 fs-2 fw-bold position-relative   px-4 py-3  btn-3d text-white rounded-0 "}
                type="submit"
              >
                {t(mode)} {i18next.language =="en" ?  <HiArrowLongRight className="ms-1" /> : <HiArrowLongLeft className="me-1" />}
              </Button>
            </div>
          </>
        )}
      </Row>
    </Form>
  );
};

export default UserInfo;
