import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Scrollbar, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { ImQuotesRight } from "react-icons/im";
import { AiFillCaretDown } from "react-icons/ai";
import { Container } from "react-bootstrap";
import personImg from "../../assets/images/testim-1.jpg";
import { Avatar } from "antd";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Testmonials = () => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    // setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <section className="section testmonials pb-5" >
 
      <Container fluid>
        <h1 className="fw-bold text-white text-center mb-5 fs-11 mb-3 pt-5 text-uppercase">
        {t("Check our clients progress")}
        </h1>
        <Swiper
        key={windowWidth}
        dir="rtl"
          className={""}
          centeredSlides={true}
        //   slidesPerView="auto"
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          grabCursor={true}
          spaceBetween={30}
          loop={true}
        >
          {/* <SwiperSlide>
            <div>
              <div className="text bg-white  position-relative pt-4 text-center">
                <ImQuotesRight />

                <p className="px-4  pb-5 fs-2 pt-0 lead text-center m-0">
                  We all had to start somewhere, but I can tell for sure that
                  Diet nation knows exactly how to effectively learn about healthy
                  lifestyle and working out. He will be there for you with his
                  professional program and nutritional diet plan. The results
                  are amazing!
                </p>
              </div>
              <AiFillCaretDown className="fs-11 mx-auto d-block text-white  down-icon" />
            </div>
            <div className="text-center text-white mt-3">
              <Avatar
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                icon={<img src={personImg} alt="person" />}
                className="mb-3"
              />
              <h5 className=" mb-1">Debbie Franklin</h5>
              <h6 className="fw-normal">Tennis Player</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div className="text bg-white  position-relative pt-4 text-center">
                <ImQuotesRight />

                <p className="px-4  pb-5 fs-2 pt-0 lead text-center m-0">
                  We all had to start somewhere, but I can tell for sure that
                  Diet nation knows exactly how to effectively learn about healthy
                  lifestyle and working out. He will be there for you with his
                  professional program and nutritional diet plan. The results
                  are amazing!
                </p>
              </div>
              <AiFillCaretDown className="fs-11 mx-auto d-block text-white  down-icon" />
            </div>
            <div className="text-center text-white mt-3">
              <Avatar
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                icon={<img src={personImg} alt="person" />}
                className="mb-3"
              />
              <h5 className=" mb-1">Debbie Franklin</h5>
              <h6 className="fw-normal">Tennis Player</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div className="text bg-white  position-relative pt-4 text-center">
                <ImQuotesRight />

                <p className="px-4  pb-5 fs-2 pt-0 lead text-center m-0">
                  We all had to start somewhere, but I can tell for sure that
                  Diet nation knows exactly how to effectively learn about healthy
                  lifestyle and working out. He will be there for you with his
                  professional program and nutritional diet plan. The results
                  are amazing!
                </p>
              </div>
              <AiFillCaretDown className="fs-11 mx-auto d-block text-white  down-icon" />
            </div>
            <div className="text-center text-white mt-3">
              <Avatar
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                icon={<img src={personImg} alt="person" />}
                className="mb-3"
              />
              <h5 className=" mb-1">Debbie Franklin</h5>
              <h6 className="fw-normal">Tennis Player</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div className="text bg-white  position-relative pt-4 text-center">
                <ImQuotesRight />

                <p className="px-4  pb-5 fs-2 pt-0 lead text-center m-0">
                  We all had to start somewhere, but I can tell for sure that
                  Diet nation knows exactly how to effectively learn about healthy
                  lifestyle and working out. He will be there for you with his
                  professional program and nutritional diet plan. The results
                  are amazing!
                </p>
              </div>
              <AiFillCaretDown className="fs-11 mx-auto d-block text-white  down-icon" />
            </div>
            <div className="text-center text-white mt-3">
              <Avatar
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                icon={<img src={personImg} alt="person" />}
                className="mb-3"
              />
              <h5 className=" mb-1">Debbie Franklin</h5>
              <h6 className="fw-normal">Tennis Player</h6>
            </div>
          </SwiperSlide> */}
          <SwiperSlide className="text-center">
            <img src="/images/progress/1.webp" className="img-fluid rounded-lg" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/2.webp" className="img-fluid rounded-lg" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/3.webp" className="img-fluid rounded-lg" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/4.webp" className="img-fluid rounded-lg" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/5.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/6.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/7.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          
          <SwiperSlide className="text-center">
            <img src="/images/progress/8.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          
          <SwiperSlide className="text-center">
            <img src="/images/progress/9.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          
          <SwiperSlide className="text-center">
            <img src="/images/progress/10.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/11.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
          <SwiperSlide className="text-center">
            <img src="/images/progress/12.webp" className="img-fluid rounded-lg mx-auto" style={{maxHeight:"450px"}} alt=""/>
          </SwiperSlide>
        </Swiper>
      </Container>
    </section>
  );
};

export default Testmonials;
