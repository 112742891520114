import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlineMenu } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import "./Menu.scss";
import logo from "../../../assets/images/logol.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { UserDataContext } from "../../../pages/Auth/UserContext/UserData.context";
import LanguageSwitch from "../../i18next/LanguageSwitch";
import { setCookie } from "../../../pages/Auth/UserContext/ForCookie";

const Menu = ({ notHome, location }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const { userData, setUserData } = useContext(UserDataContext);

  function handleShow() {
    setShow(true);
  }

  useEffect(() => {
    setShow(false);
  }, [location]);
  const handelLogout = () => {
    setCookie("access_token", "");
    localStorage.removeItem("user");
    setUserData("")
  };
  return (
    <>
      <Navbar className={"position-absolute w-100"}>
        <Container fluid className="d-flex align-items-center">
          <Link to="/">
            <img src={logo} alt="logo" style={{ maxHeight: "77px" }} />
          </Link>

          <div className="text-end">
            <LanguageSwitch />
            <Button
              variant="dark"
              className={"rounded-0 me-2 "}
              fixed="top"
              onClick={handleShow}
            >
              <AiOutlineMenu className="fs-11 text-light" />
            </Button>
          </div>
        </Container>
      </Navbar>
      <Modal
        show={show}
        className="menu-modal"
        fullscreen
        onHide={() => setShow(false)}
      >
        <Modal.Header className="border-0 px-3">
          <div>
            <button
              type="button"
              onClick={() => setShow(false)}
              class="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <Link to="/">
            <img src={logo} alt="logo" style={{ maxHeight: "77px" }} />
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              md={4}
              className="text-white flex-column d-flex mx-auto fs-9  fw-lighter my-3 text-uppercase"
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  (isActive ? "opacity-75" : "") +
                  " text-decoration-none text-white mx-3 mb-4 pb-1 "
                }
              >
                {t("home")}
              </NavLink>
              <NavLink
                to="/about-us"
                className={({ isActive }) =>
                  (isActive ? "opacity-75" : "") +
                  " text-decoration-none text-white mx-3 mb-4 pb-1 "
                }
              >
                {t("about us")}
              </NavLink>
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  (isActive ? "opacity-75" : "") +
                  " text-decoration-none text-white mx-3 mb-4 pb-1 "
                }
              >
                {t("contact us")}
              </NavLink>
              {/* <NavLink
                to="/services"
                className={({ isActive }) =>
                  (isActive ? "opacity-75" : "") +
                  " text-decoration-none text-white mx-3 mb-4 pb-1 "
                }
              >
                {t('services')}
              </NavLink> */}
              {userData.access_token ? (
                <>
                  <NavLink
                    to="/profile"
                    className={({ isActive }) =>
                      (isActive ? "opacity-75" : "") +
                      " text-decoration-none text-white mx-3 mb-4 pb-1 "
                    }
                  >
                    {t("profile")}
                  </NavLink>
                  <Button
                    variant="outline-light"
                    className=" text-uppercase  text-decoration-none  fw-lighter  mx-3 mb-4 pb-1 px-0"
                    onClick={handelLogout}
                  >
                    <BiLogOutCircle /> {t("logout")}
                  </Button>
                </>
              ) : (
                <>
                  <NavLink
                    to="/login"
                    className={({ isActive }) =>
                      (isActive ? "opacity-75" : "") +
                      " text-decoration-none text-white mx-3 mb-4 pb-1 "
                    }
                  >
                    {t("login")}
                  </NavLink>{" "}
                  <NavLink
                    to="/register"
                    className={({ isActive }) =>
                      (isActive ? "opacity-75" : "") +
                      " text-decoration-none text-white mx-3 mb-4 pb-1 "
                    }
                  >
                    {t("register")}
                  </NavLink>
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Menu;
