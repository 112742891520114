import React from "react";
import { Container } from "react-bootstrap";
import Heading from "../components/Heading/Heading";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const AboutUs = () => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"), // Translate breadcrumbName
          },
          {
            path: "/about-us",
            breadcrumbName: t("about us"), // Translate breadcrumbName
          },
        ]}
      />
      <Container className="py-5">
        {/* <h1>PERSONAL TRAINING</h1> */}
        <p>
          {t("Diet nation strategy started 8 years ago trying to educate and change people's mindset about nutrition and health, and finally it has become an artificial intelligence service that can offer you fully detailed customized plans, calculators, and nutritional education. We are a group of nutritionists and developers working on your goals scientifically and efficiently.")}
        </p>
      </Container>
    </>
  );
};

export default AboutUs;
