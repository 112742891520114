import axios from "axios";
import React, { useContext } from "react";
import { Alert, Container } from "react-bootstrap";
import Heading from "../../components/Heading/Heading";
import UserInfo from "../../components/UserInfo/UserInfo";
import { UserDataContext } from "./UserContext/UserData.context";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const Register = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const { userData } = useContext(UserDataContext);

  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"), // Translate breadcrumbName
          },
          {
            path: "/register",
            breadcrumbName: t("register"), // Translate breadcrumbName
          },
        ]}
      />
      <Container className="py-5 ">
        <h1 className="fw-bold text-dark fs-8 mb-5 text-center text-uppercase">
          {t("create your account")} {/* Translate heading */}
        </h1>
        {userData.access_token ? (
          <Alert className="mx-auto col-4 text-center text-capitalize">
            {t("already loggedin")} {/* Translate alert content */}
          </Alert>
        ) : (
          <UserInfo mode="register" />
        )}
      </Container>
    </>
  );
};

export default Register;
