import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading/Heading";
import { Card, Col, Row } from "antd";
import { Link, useParams } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import { Container } from "react-bootstrap";
import useAxios from "../Auth/Client";
import CommentsList from "./Comments";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

const BlogSingle = () => {
  const [single, setBlogsingle] = useState([]);
  const params = useParams()
const axios = useAxios()
const { t } = useTranslation();

  useEffect(() => {
    axios.get("detailBlog/" + params?.slug).then(
      (res) => {
        setBlogsingle(res.data)
      },
      () => {}
    );
  }, []);
  return (
    <>
    <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"),
          },
          {
            path: "/blog",
            breadcrumbName: t("Educate me"),
          },
        ]}
      />

      <Container className="section py-4">
      <h1>{single[i18next.language]?.title}</h1>
        <p dangerouslySetInnerHTML={{__html:single[i18next.language]?.description}}>
        
        </p>

        <div>
          <CommentsList comments={single.comments}/>
        </div>
      </Container>
    </>
  );
};

export default BlogSingle;
