import React from "react";
import "./Button3D.scss";
const Button3D = ({ text, route, className, icon }) => {
  return (
    <a
      href={route}
      className={
        className +
        " btn-3d position-relative"
      }
    >
      {text}
      {icon}
    </a>
  );
};

export default Button3D;
