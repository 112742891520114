import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
const useVisualizeData = (data) => {
  const { t } = useTranslation();
  const renderData = (data) => {
    if (data && typeof data === "object") {
      return (<>
          {renderData(data[i18next.language])}
        <ul className="list-unstyled list-group mb-3 p-0">
          {Object.entries(data).map(([key, value], i) => (
            key == "ar" || key == "en" ? null : (
                <li
                  key={key}
                  className={
                    "list-group-item list-group-item-action " +
                    (i % 2 || "list-group-item-info")
                  }
                >
                <>
                  {key.length !== 1 ? (
                    <b className="text-capitalize">
                      {t(key.replace(/_/g, " "))}
                    </b>
                  ): null}

                  {Array.isArray(value) ? (
                    renderData(value)
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: value }}
                      // dangerouslySetInnerHTML={{__html: typeof value === "object" ? renderData() : value}}
                    />
                  )}
                </>
                </li>
              )
          ))}
        </ul>
      </>
      );
    } else {
      return <p>{data}</p>;
    }
  };

  return renderData(data);
};

export default useVisualizeData;
