import React from "react";
import { Container } from "react-bootstrap";
import Heading from "../components/Heading/Heading";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const TermsofUse = () => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"), // Translate breadcrumbName
          },
          {
            path: "/terms-of-use",
            breadcrumbName: t("terms of use"), // Translate breadcrumbName
          },
        ]}
      />
      <Container className="py-5">
        <h1>{t("TERMS OF USE")}</h1>
        <p>
          {t("It does not matter if you want to lose weight, build lean muscle, or simply want to live a healthier life; a professional personal training program created specifically with you and your needs in mind will take you as far as you want to go.")}
        </p>
      </Container>
    </>
  );
};

export default TermsofUse;
