import React from "react";
import "./Home.scss";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { Col, Container, Row } from "react-bootstrap";
import Button3D from "../Button3D/Button3D";
import girl from "../../assets/images/bg_img.webp";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

const Section2 = () => {
  const { t } = useTranslation();

  return (
    <section className="section section-2">
      
      <Container>
        <Row>
          <Col md={6} className="text-center d-flex justify-content-center align-bottom">
            <img src={girl} style={{ height: "100vh", objectFit: "cover" }} className="img-fluid object-fit-cover" alt=""></img>
          </Col>
          <Col md={6} className="ms-auto text-center text-content py-5">
            <h1 className="fw-bold text-dark fs-12 mb-3 pt-5 text-uppercase">
              {t("Get in shape with our AI model")}
            </h1>
            <div className="text-danger fs-1 fw-bold">
              {t("Eat smarter  , feel better , live longer")}
            </div>
            <p className="lead fs-4 my-4">
              {t("Diet nation calculators will help you get in shape and maintain an optimum health , answer the questions and our AI model will design your ideal plan based on your food choices")}
            </p>
            <Button3D
              className={
                "text-uppercase btn mt-4 fs-2 fw-bold  px-4 py-3  btn-3d text-white rounded-0"
              }
              text={t("Check Services")}
              route="/#services"
              icon={i18next.language =="en" ?  <HiArrowLongRight className="ms-1" /> : <HiArrowLongLeft className="me-1" /> }
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section2;
