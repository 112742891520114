import React, { useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import useAxios from "../../pages/Auth/Client";
import useVisualizeData from "../Quiz/useVisualizeData";
import { Alert } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResultsModal = ({ endpoint, title,message, link }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const axios_common = useAxios();
  const { t } = useTranslation(); // Adjust the namespace if needed

  const handleClose = () => setShow(false);
  const handleShow = () => {
    axios_common
      .get(endpoint)
      .then((response) => {
        setShow(true);
        setData(response.data.data);
      })
      .catch((error) => {
        message.error(
          <>
            {t("youMustPassBy")} <b className="me-2">{title}</b>
            <Link to={link}>{t("clickHere")}</Link>
          </>
        );
      });
  };

  return (
    <>
      <Col className="mb-3">
        <Button
          variant="primary"
          className="text-nowrap w-100 text-capitalize"
          onClick={handleShow}
        >
          {title}
        </Button>
      </Col>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>{title}</Modal.Title>
          <div>

<button type="button"         onClick={() => setShow(false)}
class="btn-close" aria-label="Close"></button>
</div>

        </Modal.Header>
        <Modal.Body>
          {useVisualizeData(endpoint == 'showUserInfo' ? {'caloric equation value' : data?.caloric_equation_value,...data?.results} :data?.results)}
          <Alert
            message={<b>{t("notes")}</b>}
            description={
              <>
                <div>{t("portionAlternatives")}</div>
                <div>{t("drinkWater")}</div>
                <div>{t("fibersTablet")}</div>
                <div>{t("dontSkipBreakfast")}</div>
                <div>{t("vitaminDSupplement")}</div>
                <div>{t("switchMeals")}</div>
                <div>{t("postWorkoutCarbs")}</div>
                <div>{t("avoidSauces")}</div>
                <div>{t("avoidCannedFood")}</div>
                <div>{t("avoidEatingCarbsAlone")}</div>
              </>
            }
            className="mt-5 "
            type="warning"
            showIcon
            closable
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResultsModal;
