/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Container, Modal } from "react-bootstrap";
import Heading from "../Heading/Heading";
import "./Quiz.scss";
import { useForm, Controller } from "react-hook-form";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Alert, Progress } from "antd";
import RadioInput from "./QuestionTypes/Radio";
import Number from "./QuestionTypes/Number";
import Processing from "./Processing";
import { UserDataContext } from "../../pages/Auth/UserContext/UserData.context";
import Login from "../../pages/Auth/Login";
import UserInfo from "../UserInfo/UserInfo";
import useAxios from "../../pages/Auth/Client";
import CheckboxInput from "./QuestionTypes/Checkbox";
import useVisualizeData from "./useVisualizeData";
import { useNavigate } from "react-router-dom";
import Payment from "./Payment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextArea from "antd/es/input/TextArea";
import Text from "./QuestionTypes/Text";

const Quiz = ({ question }) => {
  const { userData, setUserData } = useContext(UserDataContext);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const axios_common = useAxios();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    trigger,
  } = useForm();
  const questions = [...question?.questions, { type: "send" }, { type: "end" }];

  // remove nullable items from the objects
  const removeNullable = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  };

  const onSubmit = async (data) => {
    try {
      const res = await axios_common.post(
        question.endpoint,
        removeNullable(watch())
      );
      setUserData({ ...userData, [question.name]: res.data });
      if (typeof res.data.data == "string") {
        next();
      } else {
        navigate("/profile/plans");
      }
    } catch (error) {
      setError(error.response.data);
    }
  };
  const [current, setCurrent] = useState(0);
  const next = async () => {
    setError();
    const output = await trigger();
    if (output) {
      if (questions.length - 1 > current) {
        setCurrent(current + 1);
      }
    }
  };
  const prev = () => {
    setError();
    if (current) {
      setCurrent(current - 1);
    }
  };
  const handleClose = () => setShow(false);
  useEffect(() => {
    reset(question.defaultValues);

    // if (userData.access_token) {
    //   axios_common.get("showUserInfo").then((res) => {
    //     reset({ ...res.data.data, ...question.defaultValues });
    //   });
    // }
  }, []);

  const listErrors = useVisualizeData(error);
  return (
    <>
      <Heading
        // desc={question?.description}
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"),
          },
          {
            path: "/quiz",
            breadcrumbName: question?.title,
          },
        ]}
      />
      <Container className=" py-5 " as="form" onSubmit={handleSubmit(onSubmit)}>
        {question?.description ? (
          <Alert
            message={<b>{question?.title}:</b>}
            description={question?.description}
            className=" col-md-6 mx-auto mb-3"
            type="info"
            showIcon
            closable
          />
        ) : null}
        {/* <Alert
          message={<b>Re-feed day:</b>}
          description={
            <>
            if you are following a calories deficit for a while and you are not doing a progress then you will need to break this plateau when you have this couple of days diet 

            </>
          }
          className=" col-md-6 mx-auto mb-3"
          type="info"
          showIcon
          closable
        /> */}
        <Col md={4} className="quiz-container mx-auto">
          <div className="quiz-head">
            <div className=" d-flex justify-content-between text-primary px-4 pt-2 align-content-center">
          {i18next.language=="en" ?    <BsArrowLeft
                className=" fs-8 p-0 btn text-primary"
                onClick={prev}
              /> : <BsArrowRight
              className=" fs-8 p-0 btn text-primary"
              onClick={prev}
            />}
              <div>{t("quiz")}</div>
              <div className="fs-4">
                {" "}
                {current}/{questions.length - 1}
              </div>
            </div>
            <Progress
              percent={(100 / (questions.length - 1)) * current}
              size="small"
              className="rounded-0 m-0"
              showInfo={false}
              status="active"
              strokeLinecap="butt"
              strokeWidth="3px"
            />
          </div>
          <div className="quiz-body px-3 pt-1 pb-3">
            {/* questions[current] */}
            <h2 className="fs-7 fw-bold mb-4 px-2">
              {questions[current].title}
            </h2>

            {(() => {
              switch (questions[current].type) {
                case "radio":
                  return (
                    <RadioInput
                      options={questions[current].options}
                      name={questions[current].name}
                      register={register}
                      next={next}
                      Controller={Controller}
                      control={control}
                      watch={watch}
                      key={questions[current].name}
                    />
                  );
                case "checkbox":
                  return (
                    <CheckboxInput
                      options={questions[current].options}
                      name={questions[current].name}
                      register={register}
                      next={next}
                      Controller={Controller}
                      control={control}
                      watch={watch}
                    />
                  );
                case "number":
                  return (
                    <Number
                      name={questions[current].name}
                      key={questions[current].name}
                      register={register}
                      onClick={next}
                      errors={errors}
                    />
                  );
                case "text":
                  return (
                      <Text
                          name={questions[current].name}
                          key={questions[current].name}
                          register={register}
                          onClick={next}
                          errors={errors}
                      />
                  );
                case "send":
                  return (
                    <div>
                      {error ? (
                        <div className="text-danger bg-danger bg-opacity-25 rounded p-2 mb-3">
                          {listErrors}
                        </div>
                      ) : null}
                      {userData.access_token ? (
                        <>
                          <Button
                            className="rounded-5 py-3 w-100 mb-3"
                            variant="outline-success shadow"
                            type="submit"
                          >
                            {t("Send Data")}
                          </Button>
                          <Button
                            className="rounded-5 py-3 w-100 mb-3"
                            variant="outline-primary shadow"
                            onClick={() => {
                              setCurrent(0);
                              setError();
                            }}
                          >
                            {t("Update Data")}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            className="rounded-5 py-3 w-100 mb-3"
                            variant="outline-success shadow"
                            onClick={() => {
                              setShow("login");
                            }}
                          >
                            {t("login")}
                          </Button>
                          <Button
                            className="rounded-5 py-3 w-100 mb-3"
                            variant="outline-primary shadow"
                            onClick={() => {
                              setShow("register");
                            }}
                          >
                            {t("Create new account")}
                            
                          </Button>
                        </>
                      )}
                    </div>
                  );

                case "end":
                  return (
                    // <Processing
                    //   data={watch}
                    //   userData={userData}
                    //   question={question.name}
                    // />
                    <Payment
                      price={question.price}
                      followup={
                        watch("isFollowUp") == "true"
                          ? question.followingup
                          : null
                      }
                      paymentLink={userData[question.name].data}
                    />
                  );
                default:
                  return "";
              }
            })()}
          </div>
        </Col>
      </Container>
      {userData.access_token ? null : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase text-center">
              {show == "login"
                ? t("login to your account")
                : t("register")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {show == "login" ? (
              <Login noheading />
            ) : (
              <UserInfo mode="register" noheading />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Quiz;
