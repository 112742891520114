import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Footer from "./components/Layout/Footer";
import Menu from "./components/Layout/Menu/Menu";
import TermsofUse from "./pages/Terms-of-Use";
import Privacy from "./pages/Privacy";
import ContactUs from "./components/Layout/ContactUs";
import { useContext, useEffect } from "react";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import Quiz from "./components/Quiz/Quiz";
import Profile from "./pages/Profile";
import { UserDataContext } from "./pages/Auth/UserContext/UserData.context";
import Questions, { CustomDiet } from "./components/Quiz/Questions";
import CustomizeDiet from "./pages/CustomizeDiet";
import Blog from "./pages/Blog/Blog";
import BlogSingle from "./pages/Blog/BlogSingle";
import i18next from "i18next";

function App() {
  const location = useLocation();
  const {userData} = useContext(UserDataContext);

  // useEffect(() => {
  //   //TODO  console.clear()
  //   return () => {
  //     if (document) {
  //       document.getElementById("root").removeAttribute("style");
  //       document.getElementsByTagName("body")[0].removeAttribute("style");
  //     }
  //   };
  // }, [location.pathname]);
  
  return (
    <main className="d-flex flex-column">
      {location.pathname !== "/" && <Menu notHome location={location} />}
      <div className="flex-grow-1">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-of-use" element={<TermsofUse />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          {/* <Route path="/services" element={<Services />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/customize-your-diet" element={<Quiz question={CustomDiet[i18next.language][0]}/>} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/blog/:slug" element={<BlogSingle/>} />

          {[...Questions[i18next.language],...CustomDiet[i18next.language]].map((q) => (
            <Route
            key={q.route}
              path={q.route}
              element={<Quiz question={q} />}
            />
          ))}

          {userData.access_token && (
            <>
              <Route path="/profile?/:tab" element={<Profile />} />
            </>
          )}
          <Route path="/*" element={<Login />} />
        </Routes>
      </div>
      {location.pathname !== "/" ? <Footer /> : null}
    </main>
  );
}

export default App;
