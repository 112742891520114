import axios from "axios";
import { useContext, useEffect } from 'react';
import { UserDataContext } from "./UserContext/UserData.context";
import { getCookie, setCookie } from "./UserContext/ForCookie";

export const url = "https://admin.diet-nation.com/api/";

const defaultOptions = {
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
};

const useAxios = () => {
  const { userData, setUserData } = useContext(UserDataContext);
  const token = userData?.access_token || getCookie("access_token");

  const axiosInstance = axios.create({
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      'Authorization': `Bearer ${token}`,
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axiosInstance.post('/auth/refresh', null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then((response) => {
          const newAccessToken = response.data.access_token;
          setCookie("access_token", newAccessToken);
          const updatedUserData = {
            ...userData,
            access_token: newAccessToken,
          };
          setUserData(updatedUserData);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }).catch(() => {
          localStorage.removeItem("user");
          setCookie("access_token", "");
          window.location.pathname = "login";
        });
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
