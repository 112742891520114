import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button } from "react-bootstrap";

const LanguageSwitch = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // Update the lang attribute of the <html> element
    document.documentElement.lang = i18next.language;
    if (i18next.language == "ar") {

      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";

    }
  }, [i18next.language]);
  return (
    <>
      <Button
      className="mx-2"
      variant="outline-light"
        onClick={() =>
          i18next.changeLanguage(i18next.language == "ar" ? "en" : "ar")
        }
      >
        {t("click")}
      </Button>
    </>
  );
};

export default LanguageSwitch;
