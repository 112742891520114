import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import { FaCrown, FaUser } from "react-icons/fa";
import { UserDataContext } from "../Auth/UserContext/UserData.context";
import useAxios from "../Auth/Client";
import { message } from "antd";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const CommentsList = ({ comments }) => {
  const { userData, setUserData } = useContext(UserDataContext);
  const axios = useAxios();
  const [replyText, setReplyText] = useState("");
  const { t } = useTranslation(); // "commentsList" corresponds to the translation namespace

  const handleReplySubmit = (e, commentId) => {
    e.preventDefault();
    // Send reply
    const replyData = {
      blog_id: 1, // Replace with the actual blog ID
      comment: replyText,
      replay_id: commentId,
    };

    axios
      .post("blog/add_comment", replyData)
      .then((response) => {
        // Handle the response as needed
        setReplyText("");
      })
      .catch((error) => {
        message.error(t("error"));
      });
  };

  return (
    <Container>
      <Row>
        <Col md={9}>
          {comments?.map((comment) => (
            <Card key={comment.id} className="mb-3">
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <FaUser className="me-2" />
                  <h3 className="text-capitalize fw-normal mb-0 lh-1 fs-2">
                    {comment.user.name}
                  </h3>
                </div>
                <p className="lead fs-5 text-muted">{comment.comment}</p>
                {comment.replies.length > 0 && (
                  <div className="mt-3">
                    <h5 className="mb-3">{t("replies")}</h5>
                    {comment.replies.map((reply) => (
                      <Card key={reply.id} className="mb-2">
                        <Card.Body>{reply.comment}</Card.Body>
                      </Card>
                    ))}
                  </div>
                )}
                {userData.access_token && (
                  <>
                    <Form onSubmit={(e) => handleReplySubmit(e, comment.id)}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder={t("writeReply")}
                          onChange={(e) => setReplyText(e.target.value)}
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        {t("submitReply")}
                      </Button>
                    </Form>
                  </>
                )}
              </Card.Body>
            </Card>
          ))}
          {userData.access_token && (
            <Form onSubmit={handleReplySubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={t("writeComment")}
                  onChange={(e) => setReplyText(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                {t("submitComment")}
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CommentsList;
