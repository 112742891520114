import React from "react";
import { Container } from "react-bootstrap";
import Heading from "../components/Heading/Heading";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"),
          },
          {
            path: "/privacy-policy",
            breadcrumbName: t("Privacy Policy"),
          },
        ]}
      />
      <Container className="py-5">
        <div>
          <h1>{t("Privacy Policy for Diet-Nation.com")}</h1>
          <p>
            {t(
              "At Diet-Nation.com, we value the privacy of our visitors. This Privacy Policy document outlines the types of personal information we collect and how it is used and safeguarded. By accessing and using our website, you consent to the terms outlined in this Privacy Policy."
            )}
          </p>

          <h2>{t("1. Information Collection and Use")}</h2>
          <h3>{t("1.1. Personal Information")}</h3>
          <p>
            {t(
              "When you visit Diet-Nation.com, we may collect personal information such as your name and email address. This information is voluntarily provided by you when you subscribe to our newsletter, submit a contact form, or interact with our website's features. We use this information to communicate with you, provide requested services or information, and improve our website's content and functionality."
            )}
          </p>

          <h3>{t("1.2. Log Data")}</h3>
          <p>
            {t(
              "Like many websites, we collect log data, which may include information such as your IP address, browser type, referral pages, and timestamps. This information is used to analyze trends, administer the site, track user movements, and gather demographic information for internal purposes. It is not linked to any personally identifiable information."
            )}
          </p>

          <h3>{t("1.3. Cookies and Similar Technologies")}</h3>
          <p>
            {t(
              "Diet-Nation.com uses cookies to enhance your browsing experience. Cookies are small text files that are stored on your device to collect standard internet log information and visitor behavior information. These cookies help us analyze website traffic, personalize content, and improve our services. You can manage or disable cookies through your browser settings, but please note that certain features of our website may not function properly without cookies."
            )}
          </p>

          <h2>{t("2. Information Sharing and Disclosure")}</h2>
          <h3>{t("2.1. Service Providers")}</h3>
          <p>
            {t(
              "We may engage trusted third-party service providers to assist us in operating our website and providing requested services. These providers have access to personal information solely for performing their tasks on our behalf and are obligated not to disclose or use it for any other purpose."
            )}
          </p>

          <h3>{t("2.2. Legal Requirements")}</h3>
          <p>
            {t(
              "We may disclose personal information if required to do so by law or in response to valid requests from governmental authorities."
            )}
          </p>

          <h2>{t("3. Data Security")}</h2>
          <p>
            {t(
              "We are committed to protecting the security of your personal information. We employ industry-standard security measures to safeguard your data from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission or electronic storage is 100% secure, and we cannot guarantee absolute security."
            )}
          </p>

          <h2>{t("4. External Links")}</h2>
          <p>
            {t(
              "Diet-Nation.com may contain links to external websites that are not operated by us. Please note that we have no control over the content and practices of these third-party sites and cannot be held responsible for their privacy policies. We encourage you to review the privacy policies of any external websites you visit."
            )}
          </p>

          <h2>{t("5. Children's Privacy")}</h2>
          <p>
            {t(
              "Our website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe we have inadvertently collected personal information from a child, please contact us, and we will promptly remove the information from our records."
            )}
          </p>

          <h2>{t("6. Changes to This Privacy Policy")}</h2>
          <p>
            {t(
              "We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the \"Last Updated\" date will be revised accordingly. We encourage you to review this Privacy Policy periodically for any updates or modifications."
            )}
          </p>

          <h2>{t("7. Contact Us")}</h2>
          <p>
            {t(
              "If you have any questions or concerns regarding this Privacy Policy, please contact us at"
            )}{" "}
            <a href="mailto:info@diet-nation.com">info@diet-nation.com</a>.{" "}
          </p>
        </div>
      </Container>
    </>
  );
};

export default Privacy;
