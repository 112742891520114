import React from "react";
import { Button } from "react-bootstrap";

const RadioInput = ({
  name,
  register,
  value,
  image,
  options,
  next,
  control,
  Controller,
  watch
}) => {
  return (
    <>
      <Controller
        // key={option.value}
        render={({ field: { onChange, value, name } }) =>
          options?.map((option) => (
            <div className={"hidden-radio " + (watch(name) == option.value ? "checked" : "")} key={option.value}>
              <input
                type="radio"
                defaultChecked={watch(name) == option.value}
                name={name}
                id={name + option.value}
                className={"d-none"}
                value={option.value}
                onClick={(e) => {
                  next();
                  onChange(e);
                }} // send value to hook form
              />
              <Button
                as="label"
                variant="outline-primary"
                className="radio-btn w-100 py-2 fs-bold mb-4"
                htmlFor={name + option.value}
              >
                {option?.image ? (
                  <img
                    src={option?.image[watch("gender")]}
                    alt="name"
                    className=" py-2"
                  />
                ) : null}
                {option?.label ? option?.label : null}
              </Button>
            </div>
          ))
        }
        name={name}
        control={control}
      />
    </>
  );
};

export default RadioInput;
