import { Breadcrumb } from "antd";
import React from "react";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import "./Heading.scss";
const Heading = ({ routes,desc }) => {
  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span className=" text-danger">{route.breadcrumbName}</span>
    ) : (
      <Link className=" text-light text-decoration-none" to={route.path}>
        {route.breadcrumbName}
      </Link>
    );
  }
  const title = routes[routes.length - 1]?.breadcrumbName;
  return (
    <header className="pt-5 position-relative text-center d-flex align-items-center justify-content-center flex-column">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>

      <h1 className="fs-11 text-white fw-bold text-uppercase pt-5">{title} </h1>
      {routes ? (
        <Breadcrumb
          separator={<span className="text-light">/</span>}
          className="  fs-3 my-3 text-uppercase"
          routes={routes}
          itemRender={itemRender}
        ></Breadcrumb>
      ) : null}
      <p className="text-white col-md-6">
        {desc}
      </p>
    </header>
  );
};

export default Heading;
