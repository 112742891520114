import React, {  useState } from "react";
import { getCookie } from "./ForCookie";
import { UserDataContext } from "./UserData.context";

const UserProvider = (props) => {
  const access_token = getCookie("access_token");
  const user = JSON.parse(localStorage.getItem("user"));
  const [userData, setUserData] = useState({
    access_token,
    user,
  });
  

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      {props.children}
    </UserDataContext.Provider>
  );
};

export default UserProvider;
