import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import arEg from "antd/locale/ar_EG";
import "antd/dist/reset.css";
import { HelmetProvider } from "react-helmet-async";
import UserProvider from "./pages/Auth/UserContext/UserProvider";
import "./components/i18next/i18nextConf"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      locale={arEg}
      theme={{
        token: {
          fontFamily: `'Cairo', sans-serif`,
        },
      }}
    >
      <HelmetProvider>
        <BrowserRouter>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
