import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Questions from "../components/Quiz/Questions";
import { UserDataContext } from "./Auth/UserContext/UserData.context";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

const Services = () => {
  const { userData, setUserData } = useContext(UserDataContext);
  const { t } = useTranslation();

  return (
    <section className="section" id="services">
      <Container className="py-5 ">
        <h1 className="fw-bold text-dark fs-12 mb-5 pt-5 text-center">
          {t("SERVICES")}
        </h1>
        <Row>
          {Questions[i18next.language].map((q) =>
           <Col md={4} key={q.route} className="my-3">
           <Link to={q.route} className="text-decoration-none">
             <Card hoverable cover={<img alt="example" src={q.image} />}>
               <Meta
                 title={q.title}
                 className="text-capitalize"
                 // description="CALORIC INTAKE CALCULATOR AND FOOD SUGGESTION"
               />
             </Card>
           </Link>
         </Col>
          )}
          {/* <Col md={4} className="my-3">
            <a
              href="https://wa.me/+201065252709"
              className="text-decoration-none"
              rel="noreferrer"
              target="_blank"
            >
              <Card
                hoverable
                cover={<img alt="example" src="/images/Online%20training.JPEG" />}
              >
                <Meta
                  title={"online training"}
                  // description="CALORIC INTAKE CALCULATOR AND FOOD SUGGESTION"
                />
              </Card>
            </a>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default Services;
