import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  TiSocialFacebook,
  TiSocialInstagram,
  TiSocialYoutube,
} from "react-icons/ti";
import { UserDataContext } from "../../pages/Auth/UserContext/UserData.context";
import { AiFillPhone } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { userData } = useContext(UserDataContext);
  const { t } = useTranslation();

  return (
    <footer className="bg-dark py-5 section text-center">
      <div className="fs-11 text-white fw-bold">
        {t("FOLLOW US ON")} <span className="text-danger">#{t("Diet_Nation")}</span>
      </div>
      <div className="text-white  fw-semibold my-3 text-uppercase">
        <NavLink
          to="/"
          className={({ isActive }) =>
            (isActive ? "border-bottom" : "") +
            " text-decoration-none text-white mx-3 pb-1"
          }
        >
          {t("home")}
        </NavLink>
        <NavLink
          to="/about-us"
          className={({ isActive }) =>
            (isActive ? "border-bottom" : "") +
            " text-decoration-none text-white mx-3 pb-1"
          }
        >
          {t("about us")}
        </NavLink>
        <NavLink
          to="/contact-us"
          className={({ isActive }) =>
            (isActive ? "border-bottom" : "") +
            " text-decoration-none text-white mx-3 pb-1"
          }
        >
          {t("contact us")}
        </NavLink>
        {/* <NavLink
          to="/services"
          className={({ isActive }) =>
            (isActive ? "border-bottom" : "") +
            " text-decoration-none text-white mx-3 pb-1"
          }
        >
          {t("services")}
        </NavLink> */}
        {userData.access_token ? (
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              (isActive ? "border-bottom" : "") +
              " text-decoration-none text-white mx-3 pb-1"
            }
          >
            {t("profile")}
          </NavLink>
        ) : (
          <>
            <NavLink
              to="/login"
              className={({ isActive }) =>
                (isActive ? "border-bottom" : "") +
                " text-decoration-none text-white mx-3 pb-1"
              }
            >
              {t("login")}
            </NavLink>
            <NavLink
              to="/register"
              className={({ isActive }) =>
                (isActive ? "border-bottom" : "") +
                " text-decoration-none text-white mx-3 pb-1"
              }
            >
              {t("register")}
            </NavLink>
          </>
        )}
      </div>
      <div className="social d-flex text-center justify-content-center align-items-center my-4">
        {/* <a href="/" className=" text-light opacity-75 mx-1 fs-7">
          <TiSocialYoutube />
        </a> */}
        <a href="tel:+201065252709" className=" text-light opacity-75 mx-1 fs-7">
          <AiFillPhone />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100093149175385" className=" text-light opacity-75 mx-1 fs-7">
          <TiSocialFacebook />
        </a>
        <a href="https://instagram.com/dietnati0n" className=" text-light opacity-75 mx-1 fs-7">
          <TiSocialInstagram />
        </a>
      </div>
      <div className="fs-3 text-light">
        <ImLocation /> {t("22 Nile Street, Mohandseen Giza, Egypt")}
      </div>
      <div className="fs-3 text-light">© 2023 {t("Diet Nation")}</div>

      <div className="text-light opacity-75 fw-light mt-2">
        <Link
          to="/terms-of-use"
          className="text-decoration-none text-light mx-2"
        >
          {t("Terms of Use")}
        </Link>
        &amp;
        <Link
          to="/privacy-policy"
          className="text-decoration-none text-light mx-2"
        >
          {t("Privacy Policy")}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
