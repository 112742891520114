import React from 'react';
import { Card } from 'react-bootstrap';

const Payment = ({ price, paymentLink,followup }) => {
  return (
    <Card className="text-center">
      <Card.Body>
        <Card.Title>Payment Details</Card.Title>
        <Card.Text>
          <span className="text-muted">Price: </span>
          <strong>200 EGP</strong>
        </Card.Text>
        {console.log(followup)}

  {followup ?      <Card.Text>
          <span className="text-muted">with follow up: </span>
          <strong>300 EGP</strong>
        </Card.Text> : null}
        <a href={paymentLink} className="btn btn-primary">
          Payment Link
        </a>
      </Card.Body>
    </Card>
  );
};

export default Payment;
