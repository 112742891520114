import axios from "axios";
import React, { useContext, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Heading from "../../components/Heading/Heading";
import { url } from "./Client";
import { setCookie } from "./UserContext/ForCookie";
import { UserDataContext } from "./UserContext/UserData.context";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import i18next from "i18next";

const Login = ({ noheading }) => {
  const { t } = useTranslation(); // Initialize the translation function

  const { userData, setUserData } = useContext(UserDataContext);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setError(false);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.post(`${url}auth/login`, { ...data }, config).then(
      (res) => {
        const userDataUpdate = res.data.data;
        setCookie(
          "access_token",
          userDataUpdate.access_token,
          userDataUpdate.expires_in * 1000
        );
        localStorage.setItem("user", JSON.stringify(userDataUpdate.user));
        setUserData({
          ...userData,
          ...userDataUpdate,
        });
        navigate("/profile");
      },
      (error) => {
        setError(true);
      }
    );
  };

  return (
    <>
      {noheading || (
        <Heading
          routes={[
            {
              path: "/",
              breadcrumbName: t("home"), // Translate breadcrumbName
            },
            {
              path: "/login",
              breadcrumbName: t("login"), // Translate breadcrumbName
            },
          ]}
        />
      )}
      <Container className={noheading || "py-5 "}>
        {noheading || (
          <h1 className="fw-bold text-dark fs-8 mb-3 text-center text-uppercase">
            {t("login to your account")} {/* Translate heading */}
          </h1>
        )}
        {userData.access_token ? (
          <Alert
            className="mx-auto col-4 text-center text-capitalize"
          >
            {t("already loggedin")} {/* Translate alert content */}
          </Alert>
        ) : (
          <Form
            className="col-8 mx-auto mt-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="flex-column justify-content-center align-items-center">
              <Col md={noheading || 6}>
                {error && (
                  <Alert
                    variant="danger"
                    className="text-center text-capitalize"
                  >
                    {t("wrong data")} {/* Translate alert content */}
                  </Alert>
                )}
                <Form.Control
                  {...register("email", { required: true })}
                  size="lg"
                  type="email"
                  placeholder={t('Your e-mail')} 
                  className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
                />
              </Col>
              <Col md={noheading || 6}>
                <Form.Control
                  {...register("password", { required: true })}
                  size="lg"
                  type="password"
                  placeholder={t('password')} 
                  className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
                />
                {noheading || (
                  <Link to="/forget-password">
                    {t('Forget Password ?')} {/* Translate link */}
                  </Link>
                )}
              </Col>

              <div className="text-center">
                <Button
                  className={
                    "text-uppercase btn mt-4 fs-2 fw-bold position-relative px-4 py-3  btn-3d text-white rounded-0"
                  }
                  type="submit"
                >
                  {t('Login')} 
                  {i18next.language =="en" ?  <HiArrowLongRight className="ms-1" /> : <HiArrowLongLeft className="me-1" />}
                </Button>
                {noheading || (
                  <div className="mt-2">
                    {t("Don't have account yet?")} 
                    <Link to="/register" className="fw-bold text-success">
                      {t('Register')} 
                    </Link>
                  </div>
                )}
              </div>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default Login;
