import React from "react";
import "./Home.scss";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import Button3D from "../Button3D/Button3D";
import Menu from "../Layout/Menu/Menu";
import { useTranslation } from 'react-i18next';
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";

import main1 from "../../assets/images/m1.webp";
import main2 from "../../assets/images/m2.webp";
import main3 from "../../assets/images/m3.webp";
import main4 from "../../assets/images/m4.webp";
import i18next from "i18next";

const Section1 = ({ move }) => {
  const { t } = useTranslation();

  const SlideContent = () => {
    return (
      <div
        className="bg-dark  bg-opacity-50 d-flex align-items-center "
        style={{ minHeight: "100vh" }}
      >
        <Container>
          <Row>
            <Col md={6} className="ms-auto text-center text-content py-5">
              <h1 className="fw-bold text-white fs-12 mb-3 text-uppercase">
                {t('Create your')}
                <br /> {t('diet')} <span className="text-danger">{t('by yourself')}</span>
              </h1>
              <div className="text-white fs-9 fw-light">{t('with Diet Nation')}</div>
              <Button3D
                className={
                  "text-uppercase btn mt-4 fs-6  px-4 py-3  btn-3d text-white rounded-0"
                }
                text={t('Check Services')}
                route="/#services"
                icon={i18next.language =="en" ?  <HiArrowLongRight className="ms-1" /> : <HiArrowLongLeft className="me-1" />}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <>
      <Menu />
      <section className="section section-1 ">
        <Carousel fade interval={5000}>
          <Carousel.Item
            style={{
              backgroundImage: `url(${main1})`,
            }}
          >
            <SlideContent />
          </Carousel.Item>

          <Carousel.Item
            style={{
              backgroundImage: `url(${main2})`,
            }}
          >
            <SlideContent />
          </Carousel.Item>

          <Carousel.Item
            style={{
              backgroundImage: `url(${main3})`,
            }}
          >
            <SlideContent />
          </Carousel.Item>

          <Carousel.Item
            style={{
              backgroundImage: `url(${main4})`,
            }}
          >
            <SlideContent />
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
};

export default Section1;
