import React, { useContext, useEffect, useState } from "react";
import {  Container, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Heading from "../components/Heading/Heading";
import Plans from "../components/UserInfo/Plans";
import UserInfo from "../components/UserInfo/UserInfo";
import { UserDataContext } from "./Auth/UserContext/UserData.context";
import useAxios from "./Auth/Client";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const param = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"),
          },
          {
            path: "/profile",
            breadcrumbName: t("profile"),
          },
        ]}
      />
      <Container>
        <Row>
          <Tabs
            defaultActiveKey={param.tab ? param.tab : "profile"}
            className="rounded m-1 fs-5 mt-5 mb-3 justify-content-center"
            mountOnEnter={true}
            variant="tabs"
          >
            <Tab eventKey="profile" title={t("viewProfile")}>
              <UserInfo mode="view" />
            </Tab>
            <Tab eventKey="update" title={t("updateProfile")}>
              <UserInfo mode="update" />
            </Tab>
            <Tab eventKey="plans" title={t("yourPlans")}>
              <Plans  />
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
