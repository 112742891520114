import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ResultsModal from "./ResultsModal";
import { Link, useLocation } from "react-router-dom";
import useAxios from "../../pages/Auth/Client";
import { Alert, message } from "antd";
import { useTranslation } from "react-i18next";

const API_ENDPOINTS = [
  {
    name: "Caloric intake and food Suggestion",
    endpoint: "showUserInfo",
    link: "/quiz/caloric-intake",
  },
  {
    name: "no more extra body water",
    endpoint: "showExtraWaterRemover",
    link: "/quiz/water-remover",
  },
  {
    name: "Your Created diet",
    endpoint: "getCustomEquation",
    link: "/customize-your-diet",
  },
  {
    name: "your ideal protein, carb and fat intake",
    endpoint: "getMacroSplitEquation",
    link: "/quiz/macro-split",
  },
];

const Plans = ({ data }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const axios_common = useAxios();
  const { t } = useTranslation();

  const handleOnlineTraining = () => {
    axios_common
      .post("updateOnlineTraining")
      .then((response) => {
        if (
          response?.data?.data?.message ===
          "You are already Paid far this service before"
        ) {
          window.location.href = "https://wa.me/+201065252709";
        } else {
          messageApi.error(
            <>
              {t("payForOnlineTraining")}
              <Link className="me-2" to="/online-training">{t("clickHere")}</Link>
            </>
          );
        }
      })
      .catch((error) => {
        message.error(
          <>
            {t("payForOnlineTraining")}
            <Link className="mx-2" to="/online-training">{t("clickHere")}</Link>
          </>
        );
      });
  };

  return (
    <Row>
      <Col md={8} className="mx-auto mt-4">
        {contextHolder}

        {location?.search.split("=")[1] !== undefined &&
          (location?.search.split("=")[1] === "failed" ? (
            <Alert type="error" message={t("paymentFailed")} banner closable />
          ) : (
            <Alert
              type="success"
              message={t("paymentSuccess")}
              banner
              closable
            />
          ))}
      </Col>

      <Col md={8} className=" mx-auto mb-5 mt-4">
        <Row>
          {API_ENDPOINTS.map((q) => (
            <ResultsModal title={t(q.name)} key={q.name} endpoint={q.endpoint} message={messageApi} link={q.link}/>
          ))}
          <Col className="mb-3">
            <Button
              variant="primary"
              className="text-nowrap w-100"
              onClick={handleOnlineTraining}
            >
              {t("onlineTraining")}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Plans;
