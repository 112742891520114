import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import Heading from "../../components/Heading/Heading";
import i18next from "i18next";
const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    next();
  };
  const [current, setCurrent] = useState(0);

  const steps = ["email", "code", "password"];
  const next = () => {
    setCurrent(current + 1);
  };
  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: "home",
          },
          {
            path: "/forget-password",
            breadcrumbName: "forget password",
          },
        ]}
      />
      <Container className="py-5 ">
        <h1 className="fw-bold text-dark fs-8 mb-3 text-center text-uppercase">
          recover your Account
        </h1>
        <Form className="col-8 mx-auto mt-5" onSubmit={handleSubmit(onSubmit)}>
          <Row className="flex-column justify-content-center align-items-center">
            {(() => {
              switch (steps[current]) {
                case "code":
                  return (
                    <Col md={6}>
                      <Form.Control
                        {...register("code", { required: true })}
                        size="lg"
                        type="number"
                        placeholder="enter code"
                        className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
                      />
                    </Col>
                  );
                case "password":
                  return (
                    <>
                      <Col md={6}>
                        <Form.Control
                          {...register("password", { required: true })}
                          size="lg"
                          type="password"
                          placeholder="password"
                          className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          {...register("confirm-password", { required: true })}
                          size="lg"
                          type="password"
                          placeholder="confirm password"
                          className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
                        />
                      </Col>
                    </>
                  );
                default:
                  return (
                    <Col md={6}>
                      <Form.Control
                        {...register("email", { required: true })}
                        size="lg"
                        type="email"
                        placeholder="Your e-mail"
                        className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
                      />
                    </Col>
                  );
              }
            })()}

            <div className="text-center">
              <Button
                className={
                  "text-uppercase btn mt-4 fs-2 fw-bold position-relative   px-4 py-3  btn-3d text-white rounded-0 "
                }
                type="submit"
              >
                Submit
                {i18next.language =="en" ?  <HiArrowLongRight className="ms-1" /> : <HiArrowLongLeft className="me-1" />}
              </Button>
            </div>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default ForgetPassword;
