import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import Button3D from "../Button3D/Button3D";
import Heading from "../Heading/Heading";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import i18next from "i18next";

const ContactUs = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"), // Translate breadcrumbName
          },
          {
            path: "/contact-us",
            breadcrumbName: t("contact us"), // Translate breadcrumbName
          },
        ]}
      />
      <Container className="py-5 my-5">
        <h1 className="fw-bold text-dark fs-12 mb-3 text-center ">{t("STAY IN TOUCH")}</h1>
        <a href="tel:+201065252709" className="fs-2 text-uppercase d-block mb-1 text-decoration-none text-center text-danger fw-semibold"> {t("+201065252709")} </a>

        {/* <Form className="col-8 mx-auto mt-5" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <Form.Control
                {...register("name", { required: true })}
                size="lg"
                type="text"
                placeholder={t("your name")} 
                className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
              />
            </Col>

            <Col md={6}>
              <Form.Control
                {...register("email", { required: true })}
                size="lg"
                type="email"
                placeholder={t("Your e-mail")} 
                className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
              />
            </Col>
            <Col md={12}>
              <Form.Control
                {...register("subject", { required: true })}
                size="lg"
                type="text"
                placeholder={t("subject")} 
                className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
              />
            </Col>

            <Col md={12}>
              <Form.Control
                {...register("message", { required: true })}
                size="lg"
                as="textarea"
                rows={5}
                placeholder={t("Your message")} 
                className="bg-light fs-2 py-3 mb-3 rounded-0 border-0 text-dark fw-light text-uppercase"
              />
            </Col>
            <Col md={4} className="mx-auto">
              <Button
                className={
                  "text-uppercase btn mt-4 fs-2 fw-bold position-relative  px-4 py-3  btn-3d text-white rounded-0 "
                }
                text={""}
                route={void 0}
                type="submit"
              >
                {t("submit message")} {i18next.language =="en" ?  <HiArrowLongRight className="ms-1" /> : <HiArrowLongLeft className="me-1" />}
              </Button>{" "}
            </Col>
          </Row>
        </Form> */}
      </Container>
    </>
  );
};

export default ContactUs;
