const baseQuestions = {
  ar: [
    {
      type: "radio",
      title: "ذكر أم أنثى",
      name: "gender",
      options: [
        { label: "ذكر", value: "X" },
        { label: "أنثى", value: "Y" },
      ],
    },
    {
      type: "radio",
      title: "اختر شكل جسمك",
      name: "body_type",
      options: [
        {
          value: "B1",
          image: { X: "/images/body/male/1.JPG", Y: "/images/body/1.JPG" },
        },
        {
          value: "B2",
          image: { X: "/images/body/male/2.JPG", Y: "/images/body/2.JPG" },
        },
        {
          value: "B3",
          image: { X: "/images/body/male/3.JPG", Y: "/images/body/3.JPG" },
        },
        {
          value: "B4",
          image: { X: "/images/body/male/4.JPG", Y: "/images/body/4.JPG" },
        },
      ],
    },
    {
      type: "number",
      title: "ما هو وزنك بالكيلو جرام ؟",
      name: "weight",
      in: "كجم",
    },
    {
      type: "number",
      title: "ما هو طولك بالسم ؟",
      name: "height",
    },
    {
      type: "number",
      title: "ما هو عمرك ؟",
      name: "age",
    },
    {
      type: "radio",
      title: "ماذا تعمل ؟",
      name: "work_type",
      options: [
        { label: "عمل مكتبي", value: "U1" },
        { label: "عمل خفيف متحرك", value: "U2" },
         { label: "مزيج من المكتب والحركة الخفيفة", value: "U3" },
        { label: " أعمال النشاط البدني", value: "U4" },
        // { label: "عمل بنشاط جسدي شاق", value: "U5" },
      ],
    },
    {
      type: "radio",
      title: "كم مرة تمارس التمارين الرياضية (أي نوع من التمارين حتى المشي)؟",
      name: "work_out_time",
      options: [
        { label: "مرات 0-2", value: "K1" },
        { label: "مرات 3-5", value: "K2" },
        { label: "أكثر من 5 مرات", value: "K3" },
        // { label: "مرة 6 مرات في الأسبوع", value: "K4" },
      ],
    },
    {
      type: "radio",
      title: "ما هدفك؟",
      name: "target",
      options: [
        { label: "فقدان الدهون", value: "T1" },
        { label: "زيادة العضلات", value: "T2" },
        { label: "الاثنين", value: "T3" },
        { label: "فقدان الوزن بشكل عام", value: "T4" },
      ],
    },
  ],
  en: [
    {
      type: "radio",
      title: "male or female",
      name: "gender",
      options: [
        { label: "male", value: "X" },
        { label: "Female", value: "Y" },
      ],
    },
    {
      type: "radio",
      title: "select your body shape",
      name: "body_type",
      options: [
        {
          value: "B1",
          image: { X: "/images/body/male/1.JPG", Y: "/images/body/1.JPG" },
        },
        {
          value: "B2",
          image: { X: "/images/body/male/2.JPG", Y: "/images/body/2.JPG" },
        },
        {
          value: "B3",
          image: { X: "/images/body/male/3.JPG", Y: "/images/body/3.JPG" },
        },
        {
          value: "B4",
          image: { X: "/images/body/male/4.JPG", Y: "/images/body/4.JPG" },
        },
      ],
    },
    {
      type: "number",
      title: "what is your weight in kgs ?",
      name: "weight",
      in: "kgs",
    },
    {
      type: "number",
      title: "what is your height in cms ? ",
      name: "height",
    },
    {
      type: "number",
      title: "what is your age",
      name: "age",
    },
    {
      type: "radio",
      title: "what do you work ?",
      name: "work_type",
      options: [
        { label: "office work", value: "U1" },
        { label: "light moving work", value: "U2" },
        { label: "physical activity work", value: "U4" },
        // { label: "hard physical activity work", value: "U5" },
      ],
    },
    {
      type: "radio",
      title: "how many times you workout ( any type of workout even walking )?",
      name: "work_out_time",
      options: [
        { label: "0-2 times", value: "K1" },
        { label: "3-5  times", value: "K2" },
        { label: "More than 5 times", value: "K3" },
        // { label: "Workout 6 times a week ", value: "K4" },
      ],
    },
    {
      type: "radio",
      title: " what is your target ?",
      name: "target",
      options: [
        { label: "lose fat ", value: "T1" },
        { label: "gain muscles ", value: "T2" },
        { label: "both", value: "T3" },
        { label: "lose weight overall ", value: "T4" },
      ],
    },
  ],
};

const dietQuestions = {
  ar: [
    {
      title: "ما هو نوع الحليب الذي تفضله؟",
      type: "radio",
      name: "favorite_milk",
      options: [
        { label: "حليب كامل الدسم", value: "full_cream_milk" },
        { label: "حليب منزوع الدسم", value: "skimmed_milk" },
        { label: "حليب اللوز", value: "almond_milk" },
        { label: "لا أفضله", value: "none" },
      ],
    },
    {
      title: "ما هو نوع األلبان الذي تفضله؟",
      type: "radio",
      name: "favorite_dairy",
      options: [
        { label: "جبنه", value: "cheese" },
        { label: "زبادي", value: "yogurt" },
        // { label: "Greek yogurt", value: "greek_yogurt" },
        { label: "كلاهما", value: "both" },
        { label: "لا أفضله", value: "none" },
      ],
    },
    {
      title: "هل تفضل البيض؟ ",
      type: "radio",
      name: "prefer_eggs",
      options: [
        { label: "نعم", value: "yes" },
        { label: "لا", value: "no" },
        // { label: "None", value: "none" },
      ],
    },

    {
      title: "أي جزء من الدجاج تفضل؟",
      type: "radio",
      name: "favorite_chicken",
      options: [
        { label: "صدور بدون عظم ", value: "boneless_breasts" },
        { label: "الفخذين", value: "thighs" },
        { label: "أجنحة", value: "wings" },
        { label: "لا افضله", value: "none" },
      ],
    },
    {
      title: "هل تأكل اللحوم؟",
      type: "radio",
      name: "eat_meat",
      options: [
        { label: "نعم", value: "yes" },
        { label: "لا", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "هل تأكل المأكوالت البحرية؟",
      type: "radio",
      name: "eat_seafood",
      options: [
        { label: "نعم", value: "yes" },
        { label: "لا", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "ما هي الحبوب التي تفضلها ؟ ",
      type: "radio",
      name: "grain",
      options: [
        { label: "أرز أبيض", value: "white_rice" },
        { label: "بسمتي", value: "basmati" },
        { label: "أرز بني ", value: "brown_rice" },
        { label: "معكرونة", value: "pasta" },
        { label: "المعكرونة", value: "noodles" },
      ],
    },
    {
      title: "هل تأكل المكسرات ؟",
      type: "radio",
      name: "eat_nuts",
      options: [
        { label: "نعم", value: "yes" },
        { label: "لا", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "ما هي الفاكهة التي تفضلها ؟",
      type: "radio",
      name: "favorite_fruits",
      options: [
        { label: "موز أخضر", value: "green_banana" },
        { label: "الموز البني", value: "brown_banana" },
        { label: "تفاحة خضراء", value: "green_apple" },
        { label: "فراولة", value: "strawberries" },
      ],
    },

    {
      title: "ماذا تستخدم في الطبخ ؟",
      type: "radio",
      name: "favorite_oil",
      options: [
        { label: "سمنة", value: "butter" },
        { label: "زيت الزيتون ", value: "olive_oil" },
        { label: "زيت النباتات ", value: "plants_oil" },
      ],
    },
    {
      title: "ما نوع الخبز الذي تفضله؟",
      type: "radio",
      name: "bread",
      options: [
        { label: "خبز بلدي", value: "baladi_bread" },
        { label: "خبز ابيض ", value: "white_bread" },
        { label: "خبز اسمر", value: "brown_bread" },
        { label: "توست أبيض ", value: "white_toast" },
        { label: "توست بني", value: "brown_toast" },
      ],
    },
    {
      title: "هل تأكل زبدة الفول السوداني؟",
      type: "radio",
      name: "peanut_butter",
      options: [
        { label: "نعم", value: "yes" },
        { label: "لا", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "هل تأكل الخضار؟",
      type: "radio",
      name: "vegetables",
      options: [
        { label: "نعم", value: "yes" },
        { label: "لا", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "ما هو نوع البطاطس الذي تفضله ؟",
      type: "radio",
      name: "potatoes",
      options: [
        { label: "بطاطس مهروسة", value: "mash_potatoes" },
        { label: "بطاطس مقلية", value: "fries" },
        { label: "البطاطا المشوية الحلوة ", value: "sweet_grilled_potatoes" },
        { label: "لا افضله", value: "none" },
      ],
    },
    {
      title: "هل تستخدم بروتين مصل اللبن؟ ",
      type: "radio",
      name: "whey_protein",
      options: [
        { label: "نعم", value: "yes" },
        { label: "لا", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "ماذا تحب للتحلية ؟",
      type: "text",
      name: "desert",
    },
    {
      title: "ما هو نوع النظام الغذائي الذي تفضله؟",
      type: "radio",
      name: "diet",
      options: [
        { label: "نظام غذائي طبيعي متوازن ", value: "normal_balanced_diet" },
        { label: "كيتو", value: "keto" },
        { label: "متقطع", value: "intermittent" },
        { label: "مداورة الكربوهيدرات", value: "carb_cycling" },
        { label: "نباتي", value: "vegetarian" },
      ],
    },
  ],
  en: [
    {
      title: "what kind of milk do you prefer?",
      type: "radio",
      name: "favorite_milk",
      options: [
        { label: "Full cream milk", value: "full_cream_milk" },
        { label: "Skimmed milk", value: "skimmed_milk" },
        { label: "Whey protein shake", value: "protein_shake" },
        { label: "almond milk", value: "almond_milk" },
      ],
    },
    {
      title: "what kind of dairy you prefer ?",
      type: "radio",
      name: "favorite_dairy",
      options: [
        { label: "cheese", value: "cheese" },
        { label: "yogurt", value: "yogurt" },
        // { label: "Greek yogurt", value: "greek_yogurt" },
        { label: "both", value: "both" },
        { label: "none ", value: "none" },
      ],
    },
    {
      title: "do you prefer eggs?",
      type: "radio",
      name: "prefer_eggs",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        // { label: "None", value: "none" },
      ],
    },

    {
      title: "what part of chicken do you prefer?",
      type: "radio",
      name: "favorite_chicken",
      options: [
        { label: "Boneless breasts", value: "boneless_breasts" },
        { label: "Thighs", value: "thighs" },
        { label: "Wings", value: "wings" },
        { label: "None", value: "none" },
      ],
    },
    {
      title: "do you eat meat ?",
      type: "radio",
      name: "eat_meat",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "do you eat seafood ?",
      type: "radio",
      name: "eat_seafood",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "what grain do you prefer?",
      type: "radio",
      name: "pasta",
      options: [
        { label: "White rice", value: "white_rice" },
        { label: "Basmati", value: "basmati" },
        { label: "Brown rice", value: "brown_rice" },
        { label: "Pasta", value: "pasta" },
        { label: "Noodles", value: "noodles" },
      ],
    },
    {
      title: "do you eat nuts ?",
      type: "radio",
      name: "eat_nuts",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "what fruits you prefer?",
      type: "radio",
      name: "favorite_fruits",
      options: [
        { label: "Green banana", value: "green_banana" },
        { label: "Brown banana", value: "brown_banana" },
        { label: "Green apple", value: "green_apple" },
        { label: "Strawberries", value: "strawberries" },
      ],
    },

    {
      title: "what do you use for cooking?",
      type: "radio",
      name: "favorite_oil",
      options: [
        { label: "butter", value: "butter" },
        { label: "olive oil", value: "olive_oil" },
        { label: "plants oil", value: "plants_oil" },
      ],
    },
    {
      title: "what type of bread do you prefer?",
      type: "radio",
      name: "bread",
      options: [
        { label: "Baladi bread", value: "baladi_bread" },
        { label: "White bread", value: "white_bread" },
        { label: "Brown bread", value: "brown_bread" },
        { label: "White toast", value: "white_toast" },
        { label: "Brown toast", value: "brown_toast" },
      ],
    },
    {
      title: "do you eat peanut butter ?",
      type: "radio",
      name: "peanut_butter",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "do you eat vegetables ?",
      type: "radio",
      name: "vegetables",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "what type of potatoes you prefer?",
      type: "radio",
      name: "potatoes",
      options: [
        { label: "Mash potatoes", value: "mash_potatoes" },
        { label: "Fries", value: "fries" },
        { label: "Sweet grilled potatoes", value: "sweet_grilled_potatoes" },
        { label: "None", value: "none" },
      ],
    },
    {
      title: "do you use whey protein ?",
      type: "radio",
      name: "whey_protein",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        // { label: "None", value: "none" },
      ],
    },
    {
      title: "what do you like for desert ?",
      type: "text",
      name: "desert",
    },
    {
      title: "what type of diet you prefer?",
      type: "radio",
      name: "diet",
      options: [
        { label: "normal balanced diet", value: "normal_balanced_diet" },
        { label: "keto", value: "keto" },
        { label: "intermittent", value: "intermittent" },
        { label: "carb cycling", value: "carb_cycling" },
        { label: "vegetarian", value: "vegetarian" },
      ],
    },
  ],
};

const onlineTrainingQuestions = {
    ar: [
      {
        title: "هل لديك اصابات ؟",
        type: "text",
        name: "injuries",
      },
      {
        title: "هل تعاني من أي مرض مزمن؟",
        type: "text",
        name: "chornic_disease",
      },
      {
        title: " منذ متى وأنت تمارس الرياضة؟",
        type: "radio",
        name: "diet",
        options: [
          { label: "سنه 2-0", value: "0-2 years" },
          { label: " سنوات 5-3", value: "3-5 years" },
          { label: "أكثر من 5 سنوات", value: "More than 5years" },
        ],
      },
    ],
    en: [
      {
        title: "do you have any injuries ?",
        type: "text",
        name: "injuries",
      },
      {
        title: "do you have any chronic disease ?",
        type: "text",
        name: "chornic_disease",
      },
      {
        title: "how long have you been working out ?",
        type: "radio",
        name: "doing_workout",
        options: [
          { label: "0-2 years", value: "0-2 years" },
          { label: "3-5 years", value: "3-5 years" },
          { label: "More than 5years", value: "More than 5years" },
        ],
      },
    ],
}

const customPricing = {
  price: "10",
  followingup: "15",
};
const followupQuestion = {
  ar: {
    type: "radio",
    title: "هل تحتاج لمتابعة؟",
    name: "isFollowUp",
    options: [
      {
        label: "مع متابعة",
        value: true,
      },
      {
        label: "بدون متابعة",
        value: false,
      },
    ],
  },
  en: {
    type: "radio",
    title: "Do you need follow up ?",
    name: "isFollowUp",
    options: [
      {
        label: "with follow up",
        value: true,
      },
      {
        label: "without follow up",
        value: false,
      },
    ],
  },
};
// const dietType = {
//   type: "radio",
//   title: "diet type",
//   name: "diet_type",
//   options: [
//     { label: "standard healthy", value: "standard_healthy" },
//     { label: "intermittent fasting", value: "intermittent_fasting" },
//     { label: "keto", value: "keto" },
//     { label: "Vegetarian keto diet ", value: "vegetarian_keto" },
//     { label: "cheat day", value: "cheat_day" },
//   ],
// };
// const Questions = [
//   {
//     title: "Caloric intake calculator and food Suggestion ",
//     endpoint: "updateUserInfo",
//     route: "/quiz/caloric-intake",
//     name: "caloric_intake",
//     image: "/images/Calorie.JPEG",
//     questions: [...baseQuestions],
//   },
//   {
//     title: "no more extra body water ",
//     endpoint: "updateWaterEquation",
//     route: "/quiz/water-remover",
//     name: "water_remover",
//     image: "/images/Water%20remover.JPEG",
//     questions: [
//       {
//         type: "radio",
//         title: " salt amount",
//         name: "salt",
//         options: [
//           { label: "1 spoon ", value: "S1" },
//           { label: "½ spoon ", value: "S2" },
//           { label: "¼ spoon", value: "S3" },
//           { label: "No salt ", value: "S4" },
//         ],
//       },
//       {
//         type: "radio",
//         title: " potato/ sweet potato intake per day ",
//         name: "potato",
//         options: [
//           { label: "100 to 200 grams  ", value: "P1" },
//           { label: "200 gram or more ", value: "P2" },
//           { label: "None", value: "P3" },
//         ],
//       },
//       {
//         type: "radio",
//         title: " smoked food intake per day ",
//         name: "smoked_food",
//         options: [
//           { label: "once in little portions ", value: "N1" },
//           { label: "once in big portions ", value: "N2" },
//           { label: "once in average portions", value: "N3" },
//           { label: "twice in little portions", value: "N4" },
//           { label: "twice in average portions", value: "N5" },
//           { label: "twice in big portions ", value: "N6" },
//         ],
//       },
//       {
//         type: "radio",
//         title: " Cheese intake per day",
//         name: "chess_intake",
//         options: [
//           { label: "50 to 100 gram ", value: "M1" },
//           { label: "50 to 100 gram ", value: "M2" },
//           { label: "More than 100", value: "M3" },
//           { label: "No cheese ", value: "M4" },
//         ],
//       },
//       {
//         type: "radio",
//         title: " bread intake per day ",
//         name: "bread_intake",
//         options: [
//           { label: "4- 6 sandwiches ", value: "BI1" },
//           { label: "2- 3 sandwiches ", value: "BI2" },
//           { label: "1 sandwiches", value: "BI3" },
//           { label: "No sandwiches  ", value: "BI4" },
//         ],
//       },
//       {
//         type: "radio",
//         title: " fruit intake per day",
//         name: "fruit_intake",
//         options: [
//           { label: "Once a day ", value: "A1" },
//           { label: "Twice a day ", value: "A2" },
//           { label: "More than twice a day", value: "A3" },
//           { label: "None", value: "A4" },
//         ],
//       },
//     ],
//   },
//   {
//     title: "your ideal protein, carb and fat intake ",
//     endpoint: "updateMacroSplitEquation",
//     route: "/quiz/macro-split",
//     name: "macro_split",
//     price:"5",
//     image: "/images/Macro%20split.JPEG",
//     questions: [
//       ...baseQuestions,
//       {
//         type: "radio",
//         title: "diet type",
//         name: "diet_type",
//         options: [
//           { label: "standard healthy", value: "standard_healthy" },
//           { label: "intermittent fasting", value: "intermittent_fasting" },
//           { label: "keto", value: "keto" },
//           { label: "cheat day", value: "cheat_day" },
//         ],
//       },
//     ],
//   },
//   {
//     title: "create your diet by yourself ",
//     endpoint: "updateCustomEquation",
//     route: "/customize-your-diet",
//     name: "customize_your_diet",
//     image: "/images/Diet%20designer.JPEG",

//     questions: [],
//   },
//   {
//     title: "online training",
//     endpoint: "updateOnlineTraining",
//     route: "/online-training",
//     name: "Online training",
//     image: "/images/Online%20training.JPEG",
//     ...customPricing,
//     questions: [followupQuestion],
//   },
//   {

//     title: "Educate me",
//     endpoint: "updateCustomEquation",
//     route: "/blog",
//     name: "blog",
//     image: "/images/Educate%20me.JPEG",
//     questions: [],
//   },
// ];

const Questions = {
  en: [
    {
      title: "Caloric intake calculator and food Suggestion ",
      endpoint: "updateUserInfo",
      route: "/quiz/caloric-intake",
      name: "caloric_intake",
      image: "/images/Calorie.JPEG",
      questions: [...baseQuestions["en"],  {
        type: "radio",
        title: " how fast do you want to see results ?",
        name: "desired_result",
        options: [
          { label: "am not on a rush ", value: "R1" },
          { label: "normal", value: "R2" },
          { label: "as soon as possible", value: "R3" },
        ],
      },],
    },
    {
      title: "no more extra body water ",
      endpoint: "updateWaterEquation",
      route: "/quiz/water-remover",
      name: "water_remover",
      image: "/images/Water%20remover.JPEG",
      questions: [
        {
          type: "radio",
          title: " salt amount",
          name: "salt",
          options: [
            { label: "1 spoon ", value: "S1" },
            { label: "½ spoon ", value: "S2" },
            { label: "¼ spoon", value: "S3" },
            { label: "No salt ", value: "S4" },
          ],
        },
        {
          type: "radio",
          title: " potato/ sweet potato intake per day ",
          name: "potato",
          options: [
            { label: "100 to 200 grams  ", value: "P1" },
            { label: "200 gram or more ", value: "P2" },
            { label: "None", value: "P3" },
          ],
        },
        {
          type: "radio",
          title: " smoked food intake per day ",
          name: "smoked_food",
          options: [
            { label: "once in little portions ", value: "N1" },
            { label: "once in big portions ", value: "N2" },
            { label: "once in average portions", value: "N3" },
            { label: "twice in little portions", value: "N4" },
            { label: "twice in average portions", value: "N5" },
            { label: "twice in big portions ", value: "N6" },
          ],
        },
        {
          type: "radio",
          title: " Cheese intake per day",
          name: "chess_intake",
          options: [
            { label: "50 to 100 gram ", value: "M1" },
            { label: "50 to 100 gram ", value: "M2" },
            { label: "More than 100", value: "M3" },
            { label: "No cheese ", value: "M4" },
          ],
        },
        {
          type: "radio",
          title: " bread intake per day ",
          name: "bread_intake",
          options: [
            { label: "4- 6 sandwiches ", value: "BI1" },
            { label: "2- 3 sandwiches ", value: "BI2" },
            { label: "1 sandwiches", value: "BI3" },
            { label: "No sandwiches  ", value: "BI4" },
          ],
        },
        {
          type: "radio",
          title: " fruit intake per day",
          name: "fruit_intake",
          options: [
            { label: "Once a day ", value: "A1" },
            { label: "Twice a day ", value: "A2" },
            { label: "More than twice a day", value: "A3" },
            { label: "None", value: "A4" },
          ],
        },
      ],
    },
    {
      title: "your ideal protein, carb and fat intake ",
      endpoint: "updateMacroSplitEquation",
      route: "/quiz/macro-split",
      name: "macro_split",
      price: "5",
      image: "/images/Macro%20split.JPEG",
      questions: [
        ...baseQuestions["en"],
        {
          type: "radio",
          title: " how fast do you want to see results ?",
          name: "desired_result",
          options: [
            { label: "am not on a rush ", value: "R1" },
            { label: "normal", value: "R2" },
            { label: "as soon as possible", value: "R3" },
          ],
        },
        {
          type: "radio",
          title: "diet type",
          name: "diet_type",
          options: [
            { label: "standard healthy", value: "standard_healthy" },
            { label: "intermittent fasting", value: "intermittent_fasting" },
            { label: "keto", value: "keto" },
            { label: "cheat day", value: "cheat_day" },
          ],
        },
      ],
    },
    {
      title: "create your diet by yourself ",
      endpoint: "updateCustomEquation",
      route: "/customize-your-diet",
      name: "customize_your_diet",
      image: "/images/Diet%20designer.JPEG",

      questions: [],
    },
    {
      title: "online training",
      endpoint: "updateOnlineTraining",
      route: "/online-training",
      name: "Online training",
      image: "/images/Online%20training.JPEG",
      ...customPricing,
      questions: [
          ...baseQuestions["en"],
          ...onlineTrainingQuestions["en"],
          followupQuestion["en"]
      ],
    },
  ],
  ar: [
    {
      title: "مُقياس استهلاك السعرات الحرارية واقتراحات للأطعمة",
      endpoint: "updateUserInfo",
      route: "/quiz/caloric-intake",
      name: "caloric_intake",
      image: "/images/Calorie.JPEG",
      questions: [...baseQuestions["ar"], {
        type: "radio",
        title: "كم سرعة تريد رؤية النتائج؟",
        name: "desired_result",
        options: [
          { label: "أنا لست مستعجل", value: "R1" },
          { label: "عادي", value: "R2" },
          { label: "بأسرع وقت ممكن", value: "R3" },
        ],
      },],
    },
    {
      title: "لا مزيد من زيادة الماء في الجسم",
      endpoint: "updateWaterEquation",
      route: "/quiz/water-remover",
      name: "water_remover",
      image: "/images/Water%20remover.JPEG",
      questions: [
        {
          type: "radio",
          title: "كمية الملح",
          name: "salt",
          options: [
            { label: "ملعقة واحدة", value: "S1" },
            { label: "نصف ملعقة", value: "S2" },
            { label: "ربع ملعقة", value: "S3" },
            { label: "بدون ملح", value: "S4" },
          ],
        },
        {
          type: "radio",
          title: "كمية البطاطس / البطاطا الحلوة يومياً",
          name: "potato",
          options: [
            { label: "من 100 إلى 200 غرام", value: "P1" },
            { label: "200 غرام أو أكثر", value: "P2" },
            { label: "بدون بطاطس", value: "P3" },
          ],
        },
        {
          type: "radio",
          title: "كمية تناول الأطعمة المدخنة يومياً",
          name: "smoked_food",
          options: [
            { label: "مرة واحدة بكميات قليلة", value: "N1" },
            { label: "مرة واحدة بكميات كبيرة", value: "N2" },
            { label: "مرة واحدة بكميات متوسطة", value: "N3" },
            { label: "مرتين بكميات قليلة", value: "N4" },
            { label: "مرتين بكميات متوسطة", value: "N5" },
            { label: "مرتين بكميات كبيرة", value: "N6" },
          ],
        },
        {
          type: "radio",
          title: "كمية تناول الجبن يومياً",
          name: "chess_intake",
          options: [
            { label: "من 50 إلى 100 غرام", value: "M1" },
            { label: "من 50 إلى 100 غرام", value: "M2" },
            { label: "أكثر من 100 غرام", value: "M3" },
            { label: "بدون جبن", value: "M4" },
          ],
        },
        {
          type: "radio",
          title: "كمية تناول الخبز يومياً",
          name: "bread_intake",
          options: [
            { label: "4- 6 شطائر", value: "BI1" },
            { label: "2- 3 شطائر", value: "BI2" },
            { label: "شطيرة واحدة", value: "BI3" },
            { label: "بدون شطائر", value: "BI4" },
          ],
        },
        {
          type: "radio",
          title: "كمية تناول الفواكه يومياً",
          name: "fruit_intake",
          options: [
            { label: "مرة واحدة في اليوم", value: "A1" },
            { label: "مرتين في اليوم", value: "A2" },
            { label: "أكثر من مرتين في اليوم", value: "A3" },
            { label: "بدون فواكه", value: "A4" },
          ],
        },
      ]
      
    },
    {
      title: "البروتين والكربوهيدرات والدهون المثالية لجسمك",
      endpoint: "updateMacroSplitEquation",
      route: "/quiz/macro-split",
      name: "macro_split",
      price: "5",
      image: "/images/Macro%20split.JPEG",
      questions: [
        /* أسئلة أساسية تمت ترجمتها وهكذا تتبع لترجمة باقي الأسئلة */
        ...baseQuestions["ar"],
        {
          type: "radio",
          title: "كم سرعة تريد رؤية النتائج؟",
          name: "desired_result",
          options: [
            { label: "أنا لست مستعجل", value: "R1" },
            { label: "عادي", value: "R2" },
            { label: "بأسرع وقت ممكن", value: "R3" },
          ],
        },
      ],
    },
    {
      title: "أنشئ نظامك الغذائي بنفسك",
      endpoint: "updateCustomEquation",
      route: "/customize-your-diet",
      name: "customize_your_diet",
      image: "/images/Diet%20designer.JPEG",
      questions: [],
    },
    {
      title: "التدريب عبر الإنترنت",
      endpoint: "updateOnlineTraining",
      route: "/online-training",
      name: "Online training",
      image: "/images/Online%20training.JPEG",
      questions: [
        ...baseQuestions["ar"],
        ...onlineTrainingQuestions["ar"],
        followupQuestion["ar"]
      ],    },
  ],
};
export const CustomDiet = {
  ar: [
    {
      ...customPricing,
      title: "نظام كيتو",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/keto",
      name: "keto",
      image: "/images/Keto.JPEG",
      description:
        "إذا كنت تبحث عن فقدان الدهون بشكل متطرف ولا تقوم برفع أثقال بشكل كبير أو إذا كنت غير نشط بشكل عام، وإذا كنت مرتاحًا مع تناول الدهون وأجهزة جسمك تعمل بشكل جيد، فإن نظام الكيتو سيكون مناسبًا لك.",
      defaultValues: { diet_type: "keto" },
      questions: [
        ...baseQuestions["ar"],
        {
          title: "ما هو نوعك المفضل من الحليب؟",
          type: "radio",
          name: "favorite_milk",
          options: [
            { label: "حليب كامل الدسم", value: "full_cream_milk" },
            { label: "حليب قليل الدسم", value: "skimmed_milk" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
            { label: "حليب اللوز", value: "almond_milk" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المنتجات الألبانية؟",
          type: "radio",
          name: "favorite_dairy",
          options: [
            { label: "جبنة قريش", value: "cottage" },
            { label: "جبنة خفيفة", value: "light_cheese" },
            // { label: "زبادي يوناني", value: "greek_yogurt" },
            { label: "أي نوع من الجبن الذي تفضله", value: "Any_cheese" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الخبز؟",
          type: "radio",
          name: "favorite_bread",
          options: [
            { label: "خبز بني", value: "brown_bread" },
            { label: "خبز أبيض", value: "white_bread" },
            { label: "خبز اللوز", value: "almond_bread" },
            // { label: "بدون", value: "none" },
          ],
        },
        {
          title: "ما هي الخضروات المفضلة لديك؟",
          type: "radio",
          name: "favorite_vegetables",
          options: [
            { label: "بطاطس مقلية", value: "potatoes_fries" },
            { label: "بطاطس حلوة", value: "sweet_potatoes" },
            { label: "سلطة", value: "salad" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من مصادر البروتين الحيواني؟",
          type: "radio",
          name: "animal_protein",
          options: [
            { label: "صدر الدجاج", value: "chicken_breast" },
            { label: "فخذ الدجاج", value: "chicken_thighs" },
            { label: "لحم أحمر", value: "red_meat" },
            { label: "مأكولات بحرية", value: "sea_food" },
          ],
        },
        {
          title:
            "ما هو نوعك المفضل من مصادر البروتين الحيواني السريعة التحضير؟",
          type: "radio",
          name: "favorite_fast_protein",
          options: [
            { label: "ديك رومي مدخن أو سلامي", value: "smoked_turkey_salami" },
            { label: "سمك السلمون المدخن", value: "smoked_salmon" },
            { label: "بيض البيضة المخفوقة", value: "omlette_eggs" },
            { label: "بيض مسلوق", value: "boiled_eggs" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المكسرات؟",
          type: "radio",
          name: "favorite_nuts",
          options: [
            { label: "كاجو", value: "cashews" },
            { label: "لوز", value: "almonds" },
            { label: "فول سوداني", value: "peanuts" },
            { label: "أنواع أخرى", value: "other_nuts" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الفاكهة؟",
          type: "radio",
          name: "favorite_fruit",
          options: [{ label: "الأفوكادو", value: "avocados" }],
        },

        {
          title: "ما هو نوعك المفضل من زيت الطهي؟",
          type: "radio",
          name: "favorite_oil",
          options: [
            { label: "زيت الزيتون", value: "olive_oil" },
            { label: "زيت جوز الهند", value: "coconut_oil" },
            { label: "زبدة", value: "butter" },
            { label: "زبدة الشيا", value: "Shea_butter" },
          ],
        },
        {
          title: "كم عدد الوجبات والوجبات الخفيفة التي تتناولها يوميًا؟",
          type: "radio",
          name: "meals",
          options: [
            { label: "وجبتين ووجبة خفيفة واحدة", value: "2_meals_1_snack" },
            { label: "3 وجبات ووجبة خفيفة واحدة", value: "3_meals_1_snack" },
            { label: "4 وجبات ووجبة خفيفة واحدة", value: "4_meals_1_snack" },
            { label: "5 وجبات ووجبة خفيفة واحدة", value: "5_meals_1_snack" },
            { label: "6 وجبات ووجبة خفيفة واحدة", value: "6_meals_1_snack" },
          ],
        },
        followupQuestion["ar"],
      ],
    },
    {
      ...customPricing,
      title: "نظام نباتي",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/vegetarian-keto",
      name: "vegetarian_keto",
      image: "/images/Vegetrian.JPEG",
      description:
        "إذا كنت ترتاح مع المشتقات الحيوانية، فإن النظام النباتي سيكون مثاليًا لك.",
      defaultValues: { diet_type: "vegetarian_keto" },
      questions: [
        ...baseQuestions["ar"],
        {
          title: "ما هو نوعك المفضل من الحليب؟",
          type: "radio",
          name: "favorite_milk",
          options: [
            { label: "حليب كامل الدسم", value: "full_cream_milk" },
            { label: "حليب قليل الدسم", value: "skimmed_milk" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
            { label: "حليب اللوز", value: "almond_milk" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المنتجات الألبانية؟",
          type: "radio",
          name: "favorite_dairy",
          options: [
            { label: "جبنة قريش", value: "cottage" },
            { label: "جبنة خفيفة", value: "light_cheese" },
            // { label: "زبادي يوناني", value: "greek_yogurt" },
            { label: "أي نوع من الجبن الذي تفضله", value: "Any_cheese" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الخبز؟",
          type: "radio",
          name: "favorite_bread",
          options: [
            { label: "خبز بني", value: "brown_bread" },
            { label: "خبز أبيض", value: "white_bread" },
            { label: "خبز اللوز", value: "almond_bread" },
            { label: "بدون", value: "none" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الحبوب؟",
          type: "radio",
          name: "favorite_grain",
          options: [
            { label: "أرز بسمتي", value: "basmati_rice" },
            { label: "أرز بني", value: "brown_rice" },
            { label: "فريكة", value: "freekeh" },
            { label: "مكرونة أو سباغيتي أو باستا", value: "noodles_pasta" },
          ],
        },
        {
          title: "ما هو وجبتك المفضلة في الصباح؟",
          name: "favorite_quick_cooking",
          type: "radio",
          options: [
            { label: "شوفان", value: "oats" },
            { label: "ذرة أو بذور صحية", value: "corn_healthy_flaxes" },
            { label: "زبادي يوناني", value: "greek_yogurt" },
            // { label: "أصناف مختلفة من اللحوم المدخنة (السلمون، الديك الرومي)", value: "cold_cuts" },
            // { label: "سلطة التونا أو سلطة السيزر", value: "tuna_salad" },

            { label: "أي نوع من حبوب الإفطار", value: "any_breakfast" },
          ],
        },
        {
          title: "ما هي الخضروات المفضلة لديك؟",
          type: "radio",
          name: "favorite_vegetables",
          options: [
            { label: "بطاطس مقلية", value: "potatoes_fries" },
            { label: "بطاطس حلوة", value: "sweet_potatoes" },
            { label: "سلطة", value: "salad" },
          ],
        },

        {
          title: "ما هو نوعك المفضل من اللحم النباتي؟",
          type: "radio",
          name: "animal_protein",
          options: [
            { label: "لحم التوفو", value: "tofu_meat" },
            { label: "لحم الصويا", value: "soy_meat" },
          ],
        },
        {
          title:
            "ما هو نوعك المفضل من مصادر البروتين الحيواني السريعة التحضير؟",
          type: "radio",
          name: "favorite_fast_protein",
          options: [
            { label: "بيض البيضة المخفوقة", value: "omlette_eggs" },
            { label: "بيض مسلوق", value: "boiled_eggs" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المكسرات؟",
          type: "radio",
          name: "favorite_nuts",
          options: [
            { label: "كاجو", value: "cashews" },
            { label: "لوز", value: "almonds" },
            { label: "فول سوداني", value: "peanuts" },
            { label: "أنواع أخرى", value: "other_nuts" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الفاكهة؟",
          type: "radio",
          name: "favorite_fruit",
          options: [
            { label: "تفاح أخضر", value: "green_apple" },
            { label: "موز أخضر", value: "green_banana" },
            { label: "برتقال", value: "orange" },
            { label: "فراولة", value: "strawberries" },
            { label: "توت أزرق", value: "blueberries" },
            { label: "توت أسود", value: "blackberries" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من زيت الطهي؟",
          type: "radio",
          name: "favorite_oil",
          options: [
            { label: "زيت الزيتون", value: "olive_oil" },
            { label: "زيت جوز الهند", value: "coconut_oil" },
            { label: "زبدة", value: "butter" },
            { label: "زبدة الشيا", value: "Shea_butter" },
          ],
        },
        {
          title: "كم عدد الوجبات والوجبات الخفيفة التي تتناولها يوميًا؟",
          type: "radio",
          name: "meals",
          options: [
            { label: "2 وجبات ووجبة خفيفة واحدة", value: "2_meals_1_snack" },
            { label: "2 وجبات ووجبتين خفيفتين", value: "2_meals_2_snack" },
            { label: "3 وجبات ووجبة خفيفة واحدة", value: "3_meals_1_snack" },
            { label: "3 وجبات ووجبتين خفيفتين", value: "3_meals_2_snack" },
            { label: "4 وجبات ووجبة خفيفة واحدة", value: "4_meals_1_snack" },
            { label: "5 وجبات ووجبة خفيفة واحدة", value: "5_meals_1_snack" },
            { label: "5 وجبات ووجبتين خفيفتين", value: "5_meals_2_snack" },
          ],
        },
        followupQuestion["ar"],
      ],
    },
    {
      ...customPricing,
      title: "نظام الصيام المتقطع",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/intermittent",
      name: "صيام_متقطع",
      description:
        "إذا كنت تحاول اتباع نظام غذائي حاد وصحي وتحاول تجديد جميع نظامك، فإن الصيام المتقطع هو الأفضل.",
      image: "/images/Intermittent.JPEG",
      defaultValues: {
        diet_type: "intermittent_fasting",
        meals: "2_meals_1_snack",
      },
      questions: [
        ...baseQuestions["ar"],
        {
          title: "ما هو نوعك المفضل من الحليب؟",
          type: "radio",
          name: "favorite_milk",
          options: [
            { label: "حليب كامل الدسم", value: "full_cream_milk" },
            { label: "حليب قليل الدسم", value: "skimmed_milk" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
            { label: "حليب اللوز", value: "almond_milk" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المنتجات الألبانية؟",
          type: "radio",
          name: "favorite_dairy",
          options: [
            { label: "جبنة قريش", value: "cottage" },
            // { label: "زبادي", value: "yogurt" },
            { label: "جبنة خفيفة", value: "light_cheese" },
            // { label: "زبادي يوناني", value: "greek_yogurt" },
            { label: "أي نوع من الجبن الذي تفضله", value: "Any_cheese" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الخبز؟",
          type: "radio",
          name: "favorite_bread",
          options: [
            { label: "خبز بني", value: "brown_bread" },
            { label: "خبز أبيض", value: "white_bread" },
            { label: "خبز اللوز", value: "almond_bread" },
            { label: "بدون", value: "none" },
          ],
        },
        {
          title: "ما هي وجبتك المفضلة في الصباح؟",
          name: "favorite_quick_cooking",
          type: "radio",
          options: [
            // { label: "شوفان", value: "oats" },
            // { label: "ذرة أو بذور صحية", value: "corn_healthy_flaxes" },
            { label: "لحم مدخن (سلمون، ديك رومي)", value: "cold_cuts" },
            { label: "سلطة التونا أو سلطة السيزر", value: "tuna_salad" },
            { label: "زبادي يوناني", value: "greek_yogurt" },

            // { label: "أي نوع من حبوب الإفطار", value: "any_breakfast" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الحبوب؟",
          type: "radio",
          name: "favorite_grain",
          options: [
            { label: "أرز بسمتي", value: "basmati_rice" },
            { label: "أرز بني", value: "brown_rice" },
            { label: "فريكة", value: "freekeh" },
            { label: "مكرونة أو سباغيتي أو باستا", value: "noodles_pasta" },
          ],
        },
        {
          title: "ما هي الخضروات المفضلة لديك؟",
          type: "radio",
          name: "favorite_vegetables",
          options: [
            { label: "بطاطس مقلية", value: "potatoes_fries" },
            { label: "بطاطس حلوة", value: "sweet_potatoes" },
            { label: "سلطة", value: "salad" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من مصدر البروتين الحيواني؟",
          type: "radio",
          name: "animal_protein",
          options: [
            { label: "صدر الدجاج", value: "chicken_breast" },
            { label: "فخذ الدجاج", value: "chicken_thighs" },
            { label: "لحم أحمر", value: "red_meat" },
            { label: "مأكولات بحرية", value: "sea_food" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من مصدر البروتين الحيواني السريع التحضير؟",
          type: "radio",
          name: "favorite_fast_protein",
          options: [
            {
              label: "لحم الديك الرومي المدخن أو السلامي",
              value: "smoked_turkey_salami",
            },
            { label: "سمك السلمون المدخن", value: "smoked_salmon" },
            { label: "بيض البيضة المخفوقة", value: "omlette_eggs" },
            { label: "بيض مسلوق", value: "boiled_eggs" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المكسرات؟",
          type: "radio",
          name: "favorite_nuts",
          options: [
            { label: "كاجو", value: "cashews" },
            { label: "لوز", value: "almonds" },
            { label: "فول سوداني", value: "peanuts" },
            { label: "أنواع أخرى", value: "other_nuts" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الفاكهة؟",
          type: "radio",
          name: "favorite_fruit",
          options: [
            { label: "تفاح أخضر", value: "green_apple" },
            { label: "موز أخضر", value: "green_banana" },
            { label: "برتقال", value: "orange" },
            { label: "فراولة", value: "strawberries" },
            { label: "توت أزرق", value: "blueberries" },
            { label: "توت أسود", value: "blackberries" },
          ],
        },

        {
          title: "ما هو نوعك المفضل من زيت الطهي؟",
          type: "radio",
          name: "favorite_oil",
          options: [
            { label: "زيت الزيتون", value: "olive_oil" },
            { label: "زيت جوز الهند", value: "coconut_oil" },
            { label: "زبدة", value: "butter" },
            { label: "زبدة الشيا", value: "Shea_butter" },
          ],
        },
        followupQuestion["ar"],
      ],
    },
    {
      ...customPricing,
      title: "نظام غذائي صحي عادي",
      endpoint: "updateCustomEquation",
      description:
        "إذا كنت تبحث عن نظام غذائي صحي متوازن ولا تتبع قيودًا غذائية معينة، فإن هذا النوع من النظام الغذائي سيناسبك.",
      route: "/quiz/custom/healthy",
      name: "صحي_عادي",
      image: "/images/Normal%20healthy.JPEG",
      defaultValues: { diet_type: "standard_healthy" },
      questions: [
        ...baseQuestions["ar"],
        {
          title: "ما هو نوعك المفضل من الحليب؟",
          type: "radio",
          name: "favorite_milk",
          options: [
            { label: "حليب كامل الدسم", value: "full_cream_milk" },
            { label: "حليب قليل الدسم", value: "skimmed_milk" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
            { label: "حليب اللوز", value: "almond_milk" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المنتجات الألبانية؟",
          type: "radio",
          name: "favorite_dairy",
          options: [
            { label: "جبنة قريش", value: "cottage" },
            { label: "جبنة خفيفة", value: "light_cheese" },
            // { label: "زبادي يوناني", value: "greek_yogurt" },
            { label: "أي نوع من الجبن الذي تفضله", value: "Any_cheese" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الخبز؟",
          type: "radio",
          name: "favorite_bread",
          options: [
            { label: "خبز بني", value: "brown_bread" },
            { label: "خبز أبيض", value: "white_bread" },
            { label: "خبز اللوز", value: "almond_bread" },
            { label: "بدون", value: "none" },
          ],
        },
        {
          title: "ما هي وجبتك المفضلة في الصباح؟",
          name: "favorite_quick_cooking",
          type: "radio",
          options: [
            // { label: "شوفان", value: "oats" },
            { label: "لحم مدخن (سلمون، ديك رومي)", value: "cold_cuts" },
            { label: "سلطة التونا أو سلطة السيزر", value: "tuna_salad" },
            { label: "زبادي يوناني", value: "greek_yogurt" },

            // { label: "ذرة أو بذور صحية", value: "corn_healthy_flaxes" },
            // { label: "أي نوع من حبوب الإفطار", value: "any_breakfast" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الحبوب؟",
          type: "radio",
          name: "favorite_grain",
          options: [
            { label: "أرز بسمتي", value: "basmati_rice" },
            { label: "أرز بني", value: "brown_rice" },
            { label: "فريكة", value: "freekeh" },
            { label: "مكرونة أو سباغيتي أو باستا", value: "noodles_pasta" },
          ],
        },
        {
          title: "ما هي الخضروات المفضلة لديك؟",
          type: "radio",
          name: "favorite_vegetables",
          options: [
            { label: "بطاطس مقلية", value: "potatoes_fries" },
            { label: "بطاطس حلوة", value: "sweet_potatoes" },
            { label: "سلطة", value: "salad" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من مصدر البروتين الحيواني؟",
          type: "radio",
          name: "animal_protein",
          options: [
            { label: "صدر الدجاج", value: "chicken_breast" },
            { label: "فخذ الدجاج", value: "chicken_thighs" },
            { label: "لحم أحمر", value: "red_meat" },
            { label: "مأكولات بحرية", value: "sea_food" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من مصدر البروتين الحيواني السريع التحضير؟",
          type: "radio",
          name: "favorite_fast_protein",
          options: [
            {
              label: "لحم الديك الرومي المدخن أو السلامي",
              value: "smoked_turkey_salami",
            },
            { label: "سمك السلمون المدخن", value: "smoked_salmon" },
            { label: "بيض البيضة المخفوقة", value: "omlette_eggs" },
            { label: "بيض مسلوق", value: "boiled_eggs" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المكسرات؟",
          type: "radio",
          name: "favorite_nuts",
          options: [
            { label: "كاجو", value: "cashews" },
            { label: "لوز", value: "almonds" },
            { label: "فول سوداني", value: "peanuts" },
            { label: "أنواع أخرى", value: "other_nuts" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الفاكهة؟",
          type: "radio",
          name: "favorite_fruit",
          options: [
            { label: "تفاح أخضر", value: "green_apple" },
            { label: "موز أخضر", value: "green_banana" },
            { label: "برتقال", value: "orange" },
            { label: "فراولة", value: "strawberries" },
            { label: "توت أزرق", value: "blueberries" },
            { label: "توت أسود", value: "blackberries" },
          ],
        },

        {
          title: "ما هو نوعك المفضل من زيت الطهي؟",
          type: "radio",
          name: "favorite_oil",
          options: [
            { label: "زيت الزيتون", value: "olive_oil" },
            { label: "زيت جوز الهند", value: "coconut_oil" },
            { label: "زبدة", value: "butter" },
            { label: "زبدة الشيا", value: "Shea_butter" },
          ],
        },
        {
          title: "كم عدد وجباتك ووجبات السناك في اليوم؟",
          type: "radio",
          name: "meals",
          options: [
            { label: "2 وجبتين ووجبة سناك واحدة", value: "2_meals_1_snack" },
            { label: "2 وجبتين ووجبتي سناك", value: "2_meals_2_snack" },
            { label: "3 وجبات ووجبة سناك واحدة", value: "3_meals_1_snack" },
            { label: "3 وجبات ووجبتي سناك", value: "3_meals_2_snack" },
            { label: "4 وجبات ووجبة سناك واحدة", value: "4_meals_1_snack" },
            { label: "5 وجبات ووجبة سناك واحدة", value: "5_meals_1_snack" },
            { label: "5 وجبات ووجبتي سناك", value: "5_meals_2_snack" },
          ],
        },
        followupQuestion["ar"],
      ],
    },
    {
      ...customPricing,
      title: "نظام غذائي ليوم الخداع",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/healthy",
      name: "يوم_الخداع",
      image: "/images/Refeed.JPEG",
      defaultValues: { diet_type: "cheat_day" },
      questions: [
        ...baseQuestions["ar"],
        {
          title: "ما هو نوعك المفضل من الحليب؟",
          type: "radio",
          name: "favorite_milk",
          options: [
            { label: "حليب كامل الدسم", value: "full_cream_milk" },
            { label: "حليب قليل الدسم", value: "skimmed_milk" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
            { label: "حليب اللوز", value: "almond_milk" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المنتجات الألبانية؟",
          type: "radio",
          name: "favorite_dairy",
          options: [
            { label: "جبنة قريش", value: "cottage" },
            { label: "جبنة خفيفة", value: "light_cheese" },
            // { label: "زبادي يوناني", value: "greek_yogurt" },
            { label: "أي نوع من الجبن الذي تفضله", value: "Any_cheese" },
            { label: "شيك بروتين مصل اللبن", value: "protein_shake" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الخبز؟",
          type: "radio",
          name: "favorite_bread",
          options: [
            { label: "خبز بني", value: "brown_bread" },
            { label: "خبز أبيض", value: "white_bread" },
            { label: "خبز اللوز", value: "almond_bread" },
            { label: "بدون", value: "none" },
          ],
        },
        {
          title: "ما هي وجبتك المفضلة في الصباح؟",
          name: "favorite_quick_cooking",
          type: "radio",
          options: [
            { label: "شوفان", value: "oats" },
            { label: "ذرة أو بذور صحية", value: "corn_healthy_flaxes" },
            { label: "زبادي يوناني", value: "greek_yogurt" },
            { label: "أي نوع من حبوب الإفطار", value: "any_breakfast" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الحبوب؟",
          type: "radio",
          name: "favorite_grain",
          options: [
            { label: "أرز بسمتي", value: "basmati_rice" },
            { label: "أرز بني", value: "brown_rice" },
            { label: "فريكة", value: "freekeh" },
            { label: "مكرونة أو سباغيتي أو باستا", value: "noodles_pasta" },
          ],
        },
        {
          title: "ما هي الخضروات المفضلة لديك؟",
          type: "radio",
          name: "favorite_vegetables",
          options: [
            { label: "بطاطس مقلية", value: "potatoes_fries" },
            { label: "بطاطس حلوة", value: "sweet_potatoes" },
            { label: "سلطة", value: "salad" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من مصدر البروتين الحيواني؟",
          type: "radio",
          name: "animal_protein",
          options: [
            { label: "صدر الدجاج", value: "chicken_breast" },
            { label: "فخذ الدجاج", value: "chicken_thighs" },
            { label: "لحم أحمر", value: "red_meat" },
            { label: "مأكولات بحرية", value: "sea_food" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من مصدر البروتين الحيواني السريع التحضير؟",
          type: "radio",
          name: "favorite_fast_protein",
          options: [
            {
              label: "لحم الديك الرومي المدخن أو السلامي",
              value: "smoked_turkey_salami",
            },
            { label: "سمك السلمون المدخن", value: "smoked_salmon" },
            { label: "بيض البيضة المخفوقة", value: "omlette_eggs" },
            { label: "بيض مسلوق", value: "boiled_eggs" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من المكسرات؟",
          type: "radio",
          name: "favorite_nuts",
          options: [
            { label: "كاجو", value: "cashews" },
            { label: "لوز", value: "almonds" },
            { label: "فول سوداني", value: "peanuts" },
            { label: "أنواع أخرى", value: "other_nuts" },
          ],
        },
        {
          title: "ما هو نوعك المفضل من الفاكهة؟",
          type: "radio",
          name: "favorite_fruit",
          options: [
            { label: "تفاح أخضر", value: "green_apple" },
            { label: "موز أخضر", value: "green_banana" },
            { label: "برتقال", value: "orange" },
            { label: "فراولة", value: "strawberries" },
            { label: "توت أزرق", value: "blueberries" },
            { label: "توت أسود", value: "blackberries" },
          ],
        },

        {
          title: "ما هو نوعك المفضل من زيت الطهي؟",
          type: "radio",
          name: "favorite_oil",
          options: [
            { label: "زيت الزيتون", value: "olive_oil" },
            { label: "زيت جوز الهند", value: "coconut_oil" },
            { label: "زبدة", value: "butter" },
            { label: "زبدة الشيا", value: "Shea_butter" },
          ],
        },
        {
          title: "كم عدد وجباتك ووجبات السناك في اليوم؟",
          type: "radio",
          name: "meals",
          options: [
            { label: "2 وجبتين ووجبة سناك واحدة", value: "2_meals_1_snack" },
            { label: "2 وجبتين ووجبتي سناك", value: "2_meals_2_snack" },
            { label: "3 وجبات ووجبة سناك واحدة", value: "3_meals_1_snack" },
            { label: "3 وجبات ووجبتي سناك", value: "3_meals_2_snack" },
            { label: "4 وجبات ووجبة سناك واحدة", value: "4_meals_1_snack" },
            { label: "5 وجبات ووجبة سناك واحدة", value: "5_meals_1_snack" },
            { label: "5 وجبات ووجبتي سناك", value: "5_meals_2_snack" },
          ],
        },
        followupQuestion["ar"],
      ],
    },
  ],
  en: [
    {
      ...customPricing,
      title: "Customize Your Diet",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/keto",
      name: "keto",
      image: "/images/Keto.JPEG",
     defaultValues: { diet_type: "keto" },
      questions: [
        ...baseQuestions["en"],
          ...dietQuestions["en"],
          followupQuestion["en"],
      ],
    },
    {
      ...customPricing,
      title: "Vegetarian diet ",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/vegetarian-keto",
      name: "vegetarian_keto",
      image: "/images/Vegetrian.JPEG	",
      description:
        "if you are fine with animal derivatives then vegetarian diet will be your ideal ",
      defaultValues: { diet_type: "vegetarian_keto" },
      questions: [
        ...baseQuestions["en"],
        ...dietQuestions["en"],
      ],
    },
    {
      ...customPricing,
      title: "Intermittent Diet",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/intermittent",
      name: "intermittent_fasting",
      description:
        "if you are trying to follow sharp diet healthy one and trying to refresh all your system then intermittent is the best ",
      image: "/images/Intermittent.JPEG	",
      defaultValues: {
        diet_type: "intermittent_fasting",
        meals: "2_meals_1_snack",
      },
      questions: [
        ...baseQuestions["en"],
        ...dietQuestions["en"],
      ],
    },
    {
      ...customPricing,
      title: "Normal healthy diet",
      endpoint: "updateCustomEquation",
      description:
        "if you are seeking a balance healthy diet and your not following specific diet restrictions then this type of diet will fit you .",
      route: "/quiz/custom/healthy",
      name: "standard_healthy",
      image: "/images/Normal%20healthy.JPEG",
      defaultValues: { diet_type: "standard_healthy" },
      questions: [
        ...baseQuestions["en"],
        ...dietQuestions["en"],
      ],
    },
    {
      ...customPricing,
      title: "Cheat day diet",
      endpoint: "updateCustomEquation",
      route: "/quiz/custom/healthy",
      name: "cheat_day",
      image: "/images/Refeed.JPEG",
      defaultValues: { diet_type: "cheat_day" },
      questions: [
        ...baseQuestions["en"],
        ...dietQuestions["en"],
      ],
    },
  ],
};

export default Questions;
