import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading/Heading";
import { Card } from "antd";
import { Link } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import { Container, Col, Row } from "react-bootstrap";
import useAxios from "../Auth/Client";
import { BsEye } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

const Blog = () => {
  const [list, setBlogList] = useState([]);
  const axios = useAxios();
  const { t } = useTranslation();

  useEffect(() => {
    axios.get("blogs").then(
      (res) => {
        setBlogList(res.data);
      },
      () => {}
    );
  }, []);

  return (
    <>
      <Heading
        routes={[
          {
            path: "/",
            breadcrumbName: t("home"),
          },
          {
            path: "/blog",
            breadcrumbName: t("Educate me"),
          },
        ]}
      />

      <section className="section">
        <Container className="py-5 ">
          <Row>
            {list.map((item) => (
              <Col md={3} key={item.id} className="my-3">
                <Link
                  to={`/blog/${item[i18next.language].slug}`}
                  className="text-decoration-none"
                >
                  <Card
                    hoverable
                    cover={<img  alt={item[i18next.language].title} src={item.image} style={{height:"150px",objectFit:"contain"}}/>}
                  >
                    <Meta title={item[i18next.language].title} 
                    // description={<div className="text-truncate" dangerouslySetInnerHTML={{__html:item[i18next.language].description}}></div>} 
                    />

                    <div className="pt-2 mt-2 text-muted d-flex align-items-center border-top">
                      <BsEye className="mx-2 lh-1 fs-3" />{" "}
                      <span className="fs-2 lh-1 fs-3">{item.views}</span>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Blog;
