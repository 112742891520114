const setCookie = (cookieName, cookieValue, time) => {
    var today = new Date();
    var expire = new Date();
    expire.setTime(today.getTime() + time);

    document.cookie = `${cookieName}=${cookieValue};expires=${expire.toGMTString()}`;
};
const getCookie = (CookieName) => {
    var v = document.cookie.match('(^|;) ?' + CookieName + '=([^;]*)(;|$)');

    return v ? v[2] : null;
};

export { setCookie, getCookie };