import React, { useEffect } from "react";
import { Helmet }  from 'react-helmet-async';
import Section1 from "../components/Home/Section1";
import Section2 from "../components/Home/Section2";
import Testmonials from "../components/Home/Testmonials";
import Footer from "../components/Layout/Footer";
import Services from "./Services";
const Home = () => {


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
      </Helmet>

      {/* <ReactFullpage
        anchors={anchors}
        navigation
        responsiveWidth={800}
        navigationTooltips={anchorsText}
        // sectionsColor={["#282c34", "#ff5f45", "#0798ec"]}
        // onLeave={(origin, destination, direction) => {
        // }}
        //fullpage options
        // licenseKey={"YOUR_KEY_HERE"}
        // sectionSelector="section"
        scrollOverflow={true}
        scrollOverflowReset={true}
        render={({ state, fullpageApi }) => {
          return (
            <>
              <ReactFullpage.Wrapper>
                <Section1 move={() => fullpageApi.moveSectionDown()} />
                <Section2 />
                <Services/>
                <Testmonials />
                <Footer />
              </ReactFullpage.Wrapper>
            </>
          );
        }}
      /> */}
       <Section1 />
                <Section2 />
                <Services/>
                <Testmonials />
                <Footer />
    </div>
  );
};

export default Home;
