import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import ar from "./ar.json";
import en from "./en.json";
// import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ["en"];
const availableLanguages = ["en", "ar"];
const resources = {
    en: {
        translation: en
      },
      ar: {
        translation: ar
      },
};
i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
  //   .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.


    debug: false,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
