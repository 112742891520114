import React from "react";
import { useTranslation } from "react-i18next";

const Error = ({error, name}) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <>

      {error && error[name]
        ? error[name]?.map((error,i) => (
            <div key={i} className="text-danger mb-3">{t(error)}
            </div>
          ))
        : null}
    </>
  );
};

export default Error;
