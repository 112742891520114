import i18next from "i18next";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Text = ({ name, register, watch, value, onClick,errors }) => {
    const { t } = useTranslation();

    return (
        <>
            <Form.Control
                {...register(name,{required:true})}
                type="text"
                className={(errors[name] ? "border-danger outline-danger shadow shadow-danger" : " " ) + " w-100 rounded-5 fs-8 fw-bold text-center shadow"}
                required
            />
            <Button variant="outline-primary" onClick={onClick} className=" mt-4 d-block mx-auto px-5 btn-lg  rounded-5 ">{t("next")}
                {i18next.language =="en" ?    <BsArrowRight className="ms-2"/> : <BsArrowLeft className="me-2"/> }
            </Button>
        </>
    );
};
export default Text;
